import { createContext, useReducer } from "react";
import { stageEditorContextReducer } from "./StageEditorContext.reducer";

const initialState = {
    currentElement: null,
};

export const StageEditorContext = createContext();

export const StageEditorContextProvider = ({ children }) => {
    const [store, dispatch] = useReducer(
        stageEditorContextReducer,
        initialState
    );

    return (
        <StageEditorContext.Provider value={[store, dispatch]}>
            {children}
        </StageEditorContext.Provider>
    );
};
