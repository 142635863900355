import { useTranslation } from "react-i18next";
import { Card } from "../Card";
import { Tab } from "../Tabs";
import { Button } from "../Button";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { userUtils } from "../../utils/userUtils";
import useQuestion from "../../hooks/useQuestion";
import { useNavigate } from "react-router-dom";

export const AccountSettingsTab = ({ ...props }) => {
    const { t } = useTranslation();
    const { question, QuestionDialog } = useQuestion();
    const navigate = useNavigate();

    const [userContext, dispatch] = useContext(UserContext);

    const [isLoadingSubscribe, setIsLoadingSubscribe] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const handleMailSubscribeClick = async () => {
        setIsLoadingSubscribe(true);

        try {
            const updateResponse = await userUtils.update(
                userContext?.user?.uuid,
                {
                    mailingAccepted: userContext?.user?.mailingAccepted
                        ? "0"
                        : "1",
                }
            );

            if (updateResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext?.user,
                        mailingAccepted: !userContext?.user?.mailingAccepted,
                    },
                });
            }

            setIsLoadingSubscribe(false);
        } catch (err) {
            console.error(err);

            setIsLoadingSubscribe(false);
        }
    };

    const handleDeleteClick = async () => {
        if (!userContext?.user?.uuid) {
            return;
        }

        let confirmText = t("question.delete_account");

        if (userContext?.user?.advertiser?.uuid) {
            confirmText += `\n\n${t("question.delete_account_advertiser")}`;
        }

        const confirm = await question(
            t("title.delete_account"),
            confirmText,
            t("button.delete"),
            t("button.cancel"),
            "destructive"
        );

        if (!confirm) {
            return;
        }

        setIsLoadingDelete(true);

        try {
            const deleteResponse = await userUtils.delete(
                userContext?.user?.uuid
            );

            if (deleteResponse.status === 200) {
                navigate("/");
                localStorage.removeItem("ar-jwt");
            }

            setIsLoadingDelete(false);
        } catch (err) {
            console.error(err);
            setIsLoadingDelete(false);
        }
    };

    return (
        <Tab {...props}>
            <Card className="mb-4">
                <h5 className="mb-1">{t("title.email_notification")}</h5>
                <p className="text-secondary mb-4">
                    {t("text.email_notification")}
                </p>
                <div className="text-right">
                    <Button
                        variant={
                            userContext?.user?.mailingAccepted
                                ? "secondaryNegative"
                                : "primary"
                        }
                        onClick={handleMailSubscribeClick}
                        isLoading={isLoadingSubscribe}
                    >
                        {userContext?.user?.mailingAccepted
                            ? t("button.unsubscribe")
                            : t("button.subscribe")}
                    </Button>
                </div>
            </Card>
            <Card variant="negative">
                <h5 className="mb-1">{t("button.delete_account")}</h5>
                <p className="text-secondary mb-4">
                    {t("text.delete_account")}
                </p>
                <div className="text-right">
                    <Button
                        variant="destructive"
                        onClick={handleDeleteClick}
                        disabled={!userContext?.user?.uuid}
                        isLoading={isLoadingDelete}
                    >
                        {t("button.delete_account")}
                    </Button>
                </div>
            </Card>
            <QuestionDialog />
        </Tab>
    );
};
