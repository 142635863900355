import classNames from "classnames";

export const Price = ({ children, discount, className, ...props }) => {
    return (
        <p className={classNames("text-right space-x-1", className)} {...props}>
            <span
                className={classNames({
                    "line-through text-tertiary": discount,
                })}
            >
                {children}
            </span>
            {!!discount && <span className="text-cash">{discount}</span>}
        </p>
    );
};
