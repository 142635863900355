import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import { StageEditorControls } from "../StageEditorControls";
import { Card } from "../../Card";

export const MVP = ({ value = [], onChange, argConfig, ...props }) => {
    const { t } = useTranslation();

    if (!value || !value.length) {
        value = [argConfig.defaultValue || {}];
    }

    const handleChange = (key, val, index) => {
        onChange(
            value.map((v, i) => {
                if (i === index) {
                    return {
                        ...v,
                        [key]: val,
                    };
                }

                return v;
            })
        );
    };

    const handleAdd = () => {
        if (!!argConfig.maxEntries && value.length >= argConfig.maxEntries) {
            return;
        }

        onChange([...value, argConfig.defaultValue || {}]);
    };

    const renderValues = value.map((v, index) => {
        return (
            <Card key={`${argConfig.label}-mvp-${index}`} className="!p-2">
                <StageEditorControls
                    value={v}
                    onChange={(key, val) => handleChange(key, val, index)}
                    elementConfig={argConfig}
                    index={index}
                />
            </Card>
        );
    });

    return (
        <div className="space-y-2">
            <div className="space-y-2">{renderValues}</div>
            <Button
                variant="secondary"
                className="w-full"
                onClick={handleAdd}
                disabled={
                    !!argConfig.maxEntries &&
                    value.length >= argConfig.maxEntries
                }
            >
                {t("button.add_element")}
            </Button>
        </div>
    );
};
