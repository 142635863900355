import { useEffect, useRef, useState } from "react";
import { advertiserUtils } from "../utils/advertiserUtils";
import { Loader } from "./Loader";
import { useTranslation } from "react-i18next";
import { Message } from "./Message";

export const Advertisers = ({
    advertisers = [],
    isLoading,
    className,
    ...props
}) => {
    const { t } = useTranslation();

    const [slide, setSlide] = useState(0);

    // TODO: fix sliding sometime
    // useEffect(() => {
    //     let slideInterval;

    //     if (wrapperRef.current) {
    //         slideInterval = setInterval(() => {
    //             let newSlide = slide + 1;

    //             if (newSlide >= 12) {
    //                 newSlide = 0;

    //                 setSlide(newSlide);

    //                 wrapperRef.current.style.transition = "none";
    //                 wrapperRef.current.style.transform = `translateX(-${
    //                     newSlide * 8.33
    //                 }%)`;

    //                 return;
    //             }

    //             setSlide(newSlide);

    //             wrapperRef.current.style.transition = "all 100ms linear";
    //             wrapperRef.current.style.transform = `translateX(-${
    //                 newSlide * 8.33
    //             }%)`;
    //         }, 3000);
    //     }

    //     return () => clearInterval(slideInterval);
    // }, [advertisers, slide]);

    const renderAdvertisers = (limit = 12) => {
        let limitedAdvertisers = advertisers;

        limitedAdvertisers = limitedAdvertisers.slice(0, limit);

        return (limitedAdvertisers || []).filter(Boolean).map((advertiser) => {
            return (
                <a
                    target="_blank"
                    href={advertiser?.websiteUrl}
                    rel="noreferrer"
                    key={`advertiser-${advertiser?.uuid}`}
                >
                    {!!advertiser?.logoUrl ? (
                        <img
                            src={advertiser?.logoUrl}
                            className="w-full grayscale opacity-60 hover:opacity-100 hover:filter-none transition"
                            alt={advertiser?.name}
                        />
                    ) : (
                        <div className="flex items-center justify-center w-full aspect-square bg-white/5 rounded-md p-2 sm:hover:bg-white/10 active:bg-white/15">
                            <span className="text-xs text-secondary truncate">
                                {advertiser?.name}
                            </span>
                        </div>
                    )}
                </a>
            );
        });
    };

    if (isLoading) {
        return (
            <div className={className}>
                <Loader className="mb-4 h-6" />
                <div className="lg:grid gap-4 grid-cols-12 hidden">
                    <Loader className="h-[48px]" duration="6.2s" />
                    <Loader className="h-[48px]" duration="6.4s" />
                    <Loader className="h-[48px]" duration="6.6s" />
                    <Loader className="h-[48px]" duration="6.8s" />
                    <Loader className="h-[48px]" duration="7s" />
                    <Loader className="h-[48px]" duration="7.2s" />
                    <Loader className="h-[48px]" duration="7.4s" />
                    <Loader className="h-[48px]" duration="7.6s" />
                    <Loader className="h-[48px]" duration="7.8s" />
                    <Loader className="h-[48px]" duration="8s" />
                    <Loader className="h-[48px]" duration="8.2s" />
                    <Loader className="h-[48px]" duration="8.4s" />
                </div>
                <div className="md:grid gap-4 grid-cols-8 hidden lg:!hidden">
                    <Loader className="h-[48px]" duration="6s" />
                    <Loader className="h-[48px]" duration="6.2s" />
                    <Loader className="h-[48px]" duration="6.4s" />
                    <Loader className="h-[48px]" duration="6.6s" />
                    <Loader className="h-[48px]" duration="6.8s" />
                    <Loader className="h-[48px]" duration="7s" />
                    <Loader className="h-[48px]" duration="7.2s" />
                    <Loader className="h-[48px]" duration="7.4s" />
                </div>
                <div className="grid gap-4 grid-cols-6 md:hidden lg:hidden">
                    <Loader className="h-[48px]" duration="6s" />
                    <Loader className="h-[48px]" duration="6.2s" />
                    <Loader className="h-[48px]" duration="6.4s" />
                    <Loader className="h-[48px]" duration="6.6s" />
                    <Loader className="h-[48px]" duration="6.8s" />
                    <Loader className="h-[48px]" duration="7s" />
                </div>
            </div>
        );
    }

    if (!isLoading && !advertisers.length) {
        return (
            <div className={className}>
                <Message variant="yellow">
                    {t("text.no_active_advertisers")}
                </Message>
            </div>
        );
    }

    return (
        <div className={className}>
            <h3 className="text-lg mb-4 text-secondary font-bold">
                {t("title.todays_advertisers")}
            </h3>
            <div className="relative">
                <div className="lg:grid gap-4 items-center grid-cols-12 hidden">
                    {renderAdvertisers(12)}
                </div>
                <div className="md:grid gap-4 items-center grid-cols-8 hidden lg:!hidden">
                    {renderAdvertisers(8)}
                </div>
                <div className="grid gap-4 items-center grid-cols-6 md:hidden lg:hidden">
                    {renderAdvertisers(6)}
                </div>
            </div>
        </div>
    );
};
