import { Dialog } from "../Dialog";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../lib/stripe";
import { useContext, useEffect, useState } from "react";
import { Button } from "../Button";
import { AdPaymentMethodForm } from "../forms/AdPaymentMethodForm";
import { SubPages, SubPage } from "../SubPages";
import { UserContext } from "../context/UserContext";
import { getCPCByDuration, getCPWCByDuration } from "../pages/CampaignPage";
import { useTranslation } from "react-i18next";
import { campaignUtils } from "../../utils/campaignUtils";
import { PriceFormatter } from "../../helpers/number.helper";

export const PublishDialog = ({
    onClose,
    open,
    campaign,
    onUpdate,
    ...props
}) => {
    const { t } = useTranslation();

    const [userContext] = useContext(UserContext);

    const [intent, setIntent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [currentPage, setCurrentPage] = useState(
        !!userContext?.user?.advertiser?.stripe?.paymentMethodId ? 1 : 0
    );

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        setCurrentPage(
            !!userContext?.user?.advertiser?.stripe?.paymentMethodId ? 1 : 0
        );
    }, [userContext?.user]);

    const options = {
        // passing the SetupIntent's client secret
        clientSecret:
            userContext?.user?.advertiser?.stripe?.setupIntentClientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: "night",
            labels: "floating",
        },
    };

    const handlePublish = async () => {
        if (campaign?.status !== 0 && campaign?.status !== 5) {
            return;
        }

        setIsLoadingPublish(true);

        try {
            const publishResponse = await campaignUtils.publish(campaign?.uuid);

            if (publishResponse.status === 200) {
                onUpdate(publishResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingPublish(false);
        }

        handleClose();

        setIsLoadingPublish(false);
    };

    const cpc = getCPCByDuration(campaign?.videoDuration);
    const cpwc = getCPWCByDuration(campaign?.websiteDuration);

    return (
        <Dialog
            title={t("title.publish")}
            onClose={handleClose}
            open={open}
            {...props}
        >
            <SubPages
                currentPage={currentPage}
                setPage={(v) => setCurrentPage(v)}
            >
                <SubPage>
                    {!userContext?.user?.advertiser?.stripe
                        ?.paymentMethodId && (
                        <Elements stripe={stripePromise} options={options}>
                            <AdPaymentMethodForm
                                returnUrl={`${window.location.origin}/payment-method-added?referrer=${window.location.href}`}
                            />
                        </Elements>
                    )}
                </SubPage>
                <SubPage>
                    <p className="mb-4 whitespace-break-spaces text-secondary">
                        {t("text.publish_campaign_1")}
                    </p>
                    <p className="text-secondary mb-2">
                        {t("label.price_overview")}
                    </p>
                    <ul>
                        <li className="text-secondary">
                            {t("text.per_watch", {
                                value: PriceFormatter.format(cpc),
                            })}
                        </li>
                        <li className="text-secondary">
                            {!!cpwc &&
                                !!campaign?.websiteUrl &&
                                t("text.per_website_watch", {
                                    value: PriceFormatter.format(cpwc),
                                })}
                        </li>
                    </ul>
                    <div className="text-right">
                        <Button
                            onClick={handlePublish}
                            disabled={
                                campaign?.status !== 0 && campaign?.status !== 5
                            }
                            isLoading={isLoadingPublish}
                        >
                            {t("button.publish")}
                        </Button>
                    </div>
                </SubPage>
            </SubPages>
        </Dialog>
    );
};
