export const DE_DE = {
    label: {
        de_de: "German",
        en_gb: "English",
        logo: "Logo",
        advertisement: "Werbung",
        prize_pool: "Preispool",
        tickets: "Tickets",
        participants: "Teilnehmer",
        your_tickets: "Deine Tickets",
        win_chance: "Gewinnchance",
        password: "Passwort",
        current_password: "Aktuelles Passwort",
        new_password: "Neues Passwort",
        repeat_new_password: "Neues Passwort wiederholen",
        video: "Video",
        website_url: "Website-URL",
        description: "Beschreibung",
        tags: "Tags",
        price: "Preis",
        limit: "Limit",
        status: "Status",
        waiting_for_payment: "Warten auf Zahlung",
        payment_confirmed: "Zahlung bestätigt",
        draft: "Entwurf",
        waiting_for_approval: "Warten auf Genehmigung",
        paused: "Pausiert",
        live: "Live",
        limit_reached: "Limit erreicht",
        declined: "Abgelehnt",
        watches: "Ansichten",
        website_watches: "Website-Ansichten",
        payment_failed: "Zahlung fehlgeschlagen",
        bank_account: "Bankkonto",
        golden_tickets: "Goldene Tickets",
        registered_since: "Registriert seit",
    },
    description: {
        website_url: "Wird am Ende deines Videos angezeigt",
        description: "Wird am Ende deines Videos angezeigt",
        campaign_website:
            "Die Zuschauer werden aufgefordert, deine Website zu besuchen, nachdem sie deine Werbung gesehen haben. Deine Website wird in adroulette.net eingebettet, um sicherzustellen, dass die Zuschauer für die ausgewählte Zeit auf deiner Website bleiben",
        campaign_tags:
            "Verwende unsere Tags, um dein Publikum gezielt anzusprechen. Wir werden unser Bestes tun, um deine Werbung mit interessierten Zuschauern abzugleichen",
        bank_account:
            "Du benötigst ein gültiges Bankkonto, um dein Preisgeld zu beanspruchen. Weitere Informationen findest du in unseren <a class='underline' href='/terms-of-service'>Nutzungsbedingungen</a>",
    },
    placeholder: {
        loading: "Lädt...",
        search: "Suche...",
    },
    title: {
        language: "Sprache",
        raffles: "Verlosungen",
        more_raffles: "Weitere Verlosungen",
        participating_raffles: "Laufende Verlosungen, an denen du teilnimmst",
        change_password: "Passwort ändern",
        dangerzone: "Gefahrenzone",
        change_interests: "Interessen ändern",
        interests: "Interessen",
        cwfa: "CWFA",
        create_campaign: "Kampagne erstellen",
        my_account: "Mein Konto",
        advertisements: "Werbungen",
        countries: "Länder",
        website: "Deine Website",
        tags: "Tags",
        legal: "Rechtliches",
        imprint: "Impressum",
        terms_of_service: "Nutzungsbedingungen",
        data_privacy: "Datenschutz",
        contact: "Kontakt",
        disclaimer: "Haftungsausschluss",
        limit: "Limit setzen",
        save_campaign: "Kampagne speichern",
        win_congratulations: "Glückwunsch, du hast gewonnen!",
        claimed_raffles: "Beanspruchte Verlosungen",
        manage_campaign: "Kampagne verwalten",
        insights: "Einblicke",
        your_campaigns: "Deine Kampagnen",
        settings: "Einstellungen",
        how_it_works: "So funktioniert's",
        guidelines: "Richtlinien",
        ad_policy: "Richtlinien",
        monthly_raffle: "Monatliche Verlosung",
    },
    subtitle: {
        advertisements: "Erstelle und verwalte deine Werbungen",
    },
    button: {
        advertisements: "Werbungen",
        choose_other_video: "Anderes Video auswählen",
        choose_video: "Video auswählen",
        remove: "Entfernen",
        claim_ticket: "Beanspruchen",
        share: "Teilen",
        claim_golden_ticket: "Beanspruchen",
        watch: "Ansehen",
        resume: "Fortsetzen",
        publish: "Veröffentlichen",
        pause: "Pause",
        save: "Speichern",
        delete: "Löschen",
        delete_account: "Konto löschen",
        change_password: "Passwort ändern",
        logout: "Abmelden",
        preview: "Vorschau",
        next: "Weiter",
        create: "Erstellen",
        back: "Zurück",
        send_email: "E-Mail senden",
        set: "Setzen",
        cancel: "Abbrechen",
        claim_prize: "Preis beanspruchen",
        new_campaign: "Neue Kampagne",
        yes: "Ja",
        no: "Nein, danke",
        seconds: "{{value}} Sekunden",
        one_minute: "1 Minute",
        week: "Woche",
        month: "Monat",
    },
    text: {
        days_hours_minutes_seconds_left:
            "<span class='font-bold'>{{days}} Tage</span>, <span class='font-bold'>{{hours}} Stunden</span>, <span class='font-bold'>{{minutes}} Minuten</span> und <span class='font-bold'>{{seconds}} Sekunden</span> verbleibend",
        hours_minutes_seconds_left:
            "<span class='font-bold'>{{hours}} Stunden</span>, <span class='font-bold'>{{minutes}} Minuten</span> und <span class='font-bold'>{{seconds}} Sekunden</span> verbleibend",
        minutes_seconds_left:
            "<span class='font-bold'>{{minutes}} Minuten</span> und <span class='font-bold'>{{seconds}} Sekunden</span> verbleibend",
        seconds_left:
            "<span class='font-bold'>{{seconds}} Sekunden</span> verbleibend",
        of_watches: "von {{value}} Ansichten",
        claim_prize:
            "Du kannst jetzt deinen Preis von <span class='text-cash'>{{value}}</span> beanspruchen",
        no_raffles_available:
            "Derzeit sind keine weiteren offenen Verlosungen verfügbar. Bitte schau später wieder vorbei.",
        campaign_declined:
            "<p>Es tut uns leid, dir mitteilen zu müssen, dass deine Kampagne aus folgendem Grund abgelehnt wurde:</p><p class='my-2 font-bold ml-4 italic'>{{value}}</p><p>Bitte stelle sicher, dass deine Kampagne unseren <a href='#' class='underline'>Werberichtlinien</a> entspricht.</p><p>Nachdem du deine Kampagne aktualisiert hast, werden wir sie gerne erneut überprüfen!</p>",
        payment_failed:
            "Die Zahlung für dein Abonnement ist fehlgeschlagen, bitte füge eine gültige Zahlungsmethode hinzu",
        campaign_waiting_for_approval:
            "Deine Kampagne wird derzeit von unserem Team überprüft, um die Einhaltung unserer <a href='#' class='underline'>Werberichtlinien</a> sicherzustellen. Dieser Prozess dauert in der Regel 1-3 Tage. Vielen Dank für deine Geduld, während wir die Qualitätsstandards einhalten.",
        campaign_draft:
            "Deine Werbung ist derzeit ein Entwurf, passe deine Werbung an deine Anforderungen an und veröffentliche sie",
        ad_website_info:
            "Stelle sicher, dass adroulette.net deine Website in einem iframe anzeigen darf. Du kannst deine Website in der Vorschau testen",
        monthly_raffle:
            "Du kannst an unserer monatlichen Verlosung teilnehmen, indem du goldene Tickets durch den Besuch von Sponsor-Websites erwirbst",
        delete_account:
            "Nach der Löschung werden alle Kontodaten und alles, was mit diesem Konto zusammenhängt, aus unserem System gelöscht. Dies bedeutet, dass du nicht mehr an bevorstehenden Verlosungen teilnehmen, Geld abheben oder auf das Konto zugreifen kannst.",
        no_data_available: "Keine Daten verfügbar",
        per_watch: "{{value}} / Ansicht",
        per_website_watch: "{{value}} / Website-Ansicht",
        watches: "{{value}} Ansichten",
        uploading: "Hochladen...",
        disclaimer:
            "Wir schätzen deine Zeit und das solltest du auch. Wir wissen, dass es verlockend ist, einen Geldpreis zu gewinnen, aber manchmal ist es gut, eine Pause vom Ansehen von Werbung zu machen und etwas erfüllenderes zu tun. Entdecke, treffe deine Geliebten, sei produktiv, was auch immer dich glücklich macht",
        unlimited: "Unbegrenzt",
        volume: "Volumen: <b>{{value}}</b>",
        campaign_to_draft:
            "Deine Kampagne wird wieder in den Entwurfsmodus versetzt, wenn du ein neues Video hochlädst",
        waiting_for_payment:
            "Deine Anfrage wird bearbeitet, normalerweise dauert es 1 - 3 Tage. Wir werden unser Bestes tun, um das Preisgeld so schnell wie möglich auf dein Bankkonto zu überweisen",
        payment_confirmed:
            "Wir haben dein Geld überwiesen, es sollte in den nächsten Tagen auf deinem Bankkonto eintreffen",
        valid_bank_account_needed:
            "Du benötigst ein gültiges Bankkonto, um dein Preisgeld zu beanspruchen, du kannst es in den Kontoeinstellungen eingeben",
        welcome_back_1:
            "Willkommen zurück <span class='text-gradient-primary'>{{value}}</span>, schön, dich wiederzusehen!",
        welcome_back_2:
            "Willkommen zurück <span class='text-gradient-primary'>{{value}}</span>, was machen Sachen?",
        visit_website_question:
            "Wow, du hast ein <span class='text-gradient-gold'>goldenes Ticket</span> gefunden! Möchtest du die Website unseres Sponsors besuchen, um es zu beanspruchen?",
        golden_ticket_info:
            "Mit goldenen Tickets kannst du an unserer monatlichen Verlosung teilnehmen und hast die Chance, mit einem großen Geldpreis ins neue Jahr zu starten!",
        ad_seconds_left: "Werbung, {{value}} Sekunden verbleibend...",
    },
    validate: {
        user_interests:
            "Du musst mindestens 3 und maximal 5 Interessen auswählen",
        limit: "Das Limit muss höher als die aktuellen Ansichten sein",
    },

    INTEREST_ELECTRONICS: "Elektronik",
    INTEREST_FASHION: "Mode",
    INTEREST_BOOKS: "Bücher",
    INTEREST_FOOD: "Essen",
    INTEREST_TRAVEL: "Reisen",
    INTEREST_MUSIC: "Musik",
    INTEREST_SPORTS: "Sport",
    INTEREST_HEALTH: "Gesundheit",
    INTEREST_HOME: "Zuhause",
    INTEREST_BEAUTY: "Schönheit",
    INTEREST_ART: "Kunst",
    INTEREST_TECHNOLOGY: "Technologie",
    INTEREST_OUTDOORS: "Outdoor",
    INTEREST_TOYS: "Spielzeug",
    INTEREST_PETS: "Haustiere",
    INTEREST_EDUCATION: "Bildung",
    INTEREST_FITNESS: "Fitness",
    INTEREST_CRAFTS: "Handwerk",
    INTEREST_MOVIES: "Filme",
    INTEREST_AUTOMOTIVE: "Automobil",
    INTEREST_GARDENING: "Gartenarbeit",
    INTEREST_JEWELRY: "Schmuck",
    INTEREST_COOKING: "Kochen",
    INTEREST_PHOTOGRAPHY: "Fotografie",
    INTEREST_COLLECTIBLES: "Sammelobjekte",
    INTEREST_TOOLS: "Werkzeuge",
    INTEREST_GIFTS: "Geschenke",
    INTEREST_FURNITURE: "Möbel",
    INTEREST_PARTY: "Party",
    INTEREST_OFFICE: "Büro",
    INTEREST_BABY: "Baby",
    INTEREST_VINTAGE: "Vintage",
    INTEREST_HOBBIES: "Hobbys",
    INTEREST_DIY: "DIY",
    INTEREST_GADGETS: "Gadgets",
    INTEREST_WELLNESS: "Wellness",
    INTEREST_ACCESSORIES: "Accessoires",
    INTEREST_ORGANIC: "Bio",
    INTEREST_GAMING: "Gaming",
    INTEREST_DECORATION: "Dekoration",
    INTEREST_NUTRITION: "Ernährung",
    INTEREST_COSMETICS: "Kosmetik",
    INTEREST_FOOTWEAR: "Schuhe",
    INTEREST_APPS: "Apps",
    INTEREST_INVESTING: "Investieren",

    COUNTRY_DE: "Deutschland",
    COUNTRY_TR: "Türkiye",

    ERROR_CAMPAIGN_LIMIT_REACHED:
        "Du hast die maximale Anzahl an Kampagnen erreicht",
    ERROR_NETWORK:
        "Es gab ein Netzwerkproblem, stelle sicher, dass du eine Internetverbindung hast, oder versuche es später erneut",
    ERROR_API_RATE_LIMIT_EXCEEDED:
        "Du wurdest vorübergehend gesperrt, weil du zu viele Anfragen gestellt hast, bitte versuche es später erneut",
    ERROR_INVALID_FILTERS: "Ungültige Filter, bitte versuche es später erneut",
    ERROR_LOGIN_INCORRECT:
        "Der eingegebene Benutzername oder das Passwort war falsch, bitte versuche es erneut.",
    ERROR_DELETE:
        "Beim Löschvorgang ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
    ERROR_VIDEO_TOO_LARGE: "Das Video muss 20 MB oder kleiner sein",
    ERROR_VIDEO_TOO_LARGE_DETAIL:
        "Dieses Video überschreitet unsere Größenlimits: {{value}}/20 MB",
    ERROR_NO_CAMPAIGN_FOUND:
        "Derzeit laufen keine Werbungen, bitte versuche es später erneut",
    ERROR_PASSWORD_MATCH: "Die eingegebenen Passwörter stimmen nicht überein",
    ERROR_INCORRECT_PASSWORD: "Das eingegebene Passwort war falsch",
    ERROR_MISSING_FIELDS: "Bitte fülle alle erforderlichen Felder aus",
    ERROR_PASSWORD_TOO_SHORT:
        "Dein Passwort muss mindestens 12 Zeichen lang sein",
    ERROR_INVALID_INTERESTS: "Die eingegebenen Interessen sind nicht gültig",
    ERROR_WRONG_VIDEO_FORMAT:
        "Dieses Video hat ein ungültiges Format ({{value}}), erlaubte Typen sind video/mp4",
    ERROR_VIDEO_TOO_LONG: "Das Video muss kürzer als 30 Sekunden sein",
    ERROR_VIDEO_TOO_SHORT: "Das Video muss länger als 5 Sekunden sein",
    ERROR_INVALID_AD_KEY:
        "Der Anzeigen-Schlüssel ist ungültig, bitte versuche es mit einer anderen Anzeige",
    ERROR_WATCH_VERIFICATION_FAILED:
        "Die Signaturüberprüfung ist fehlgeschlagen, bitte versuche es mit einer anderen Anzeige erneut",
    ERROR_INTERNAL: "Etwas ist schiefgelaufen, bitte versuche es später erneut",
    ERROR_CASH_OUT_OPTIONS_NOT_SET:
        "Bitte füge ein gültiges Bankkonto für dein Konto hinzu",
    ERROR_RAFFLE_NOT_FOUND:
        "Entschuldigung, diese Verlosung konnte nicht gefunden werden",
    ERROR_FORBIDDEN: "Du bist nicht berechtigt, dies zu tun",
    ERROR_RAFFLE_ALREADY_CLAIMED: "Diese Verlosung wurde bereits beansprucht",
    ERROR_MISSING_BANK_ACCOUNT:
        "Dieser Benutzer hat kein Bankkonto eingerichtet",
    ERROR_WEBSITE_ALREADY_CLAIMED:
        "Du hast diesen Website-Besuch bereits beansprucht",
    ERROR_WATCH_ALREADY_CLAIMED:
        "Du hast diese Werbungsansicht bereits beansprucht",
    ERROR_GOLDEN_TICKET_NEEDED:
        "Du benötigst ein goldenes Ticket, um an dieser Verlosung teilzunehmen",

    SUCCESS_DELETE_CAMPAIGN: "Kampagne gelöscht",
    SUCCESS_UPDATE: "Gespeichert",
    SUCCESS_CLAIM_TICKET: "Ticket beansprucht",
    SUCCESS_LOGIN: "Willkommen zurück!",
    SUCCESS_RAFFLE_CLAIMED: "Preis beansprucht",
};
