import classNames from "classnames";

export const Headline = ({ title, size = "medium" }) => {
    return (
        <div>
            <h2
                className={classNames("font-bold", {
                    "text-sm": size === "extraSmall",
                    "text-base": size === "small",
                    "text-lg": size === "medium",
                    "text-xl": size === "large",
                    "text-2xl": size === "extraLarge",
                })}
            >
                {title}
            </h2>
        </div>
    );
};
