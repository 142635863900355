export const applicationContextReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_CURRENT_ADVERTISERS":
            return {
                ...prevState,
                currentAdvertisers: action.payload,
            };
        case "SET_RAFFLES": {
            return {
                ...prevState,
                raffles: action.payload,
            };
        }
        case "SET_WON_RAFFLES": {
            return {
                ...prevState,
                wonRaffles: action.payload,
            };
        }
        case "SET_MY_RAFFLES": {
            return {
                ...prevState,
                myRaffles: action.payload,
            };
        }
        case "SET_IS_LOADING": {
            return {
                ...prevState,
                isLoading: action.payload,
            };
        }
        case "UPDATE_RAFFLE_WITH_USER":
            return {
                ...prevState,
                raffles: prevState.raffles.map((raffle) =>
                    raffle.uuid === action.payload.raffle.uuid
                        ? action.payload.raffle
                        : raffle
                ),
                myRaffles: prevState.myRaffles.map((raffleUser) =>
                    raffleUser.raffle.uuid === action.payload.raffle.uuid
                        ? action.payload.raffleUser
                        : raffleUser
                ),
            };
        default: {
            return prevState;
        }
    }
};
