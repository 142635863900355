import { useTranslation } from "react-i18next";
import { Select } from "../Select";

export const LanguageSelect = ({ ...props }) => {
    const { t } = useTranslation();

    return (
        <Select
            options={[
                {
                    label: t("label.de_de"),
                    value: "de_de",
                },
                {
                    label: t("label.en_gb"),
                    value: "en_gb",
                },
            ]}
            sort
            {...props}
        />
    );
};
