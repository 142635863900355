import classNames from "classnames";

import { Button } from "./Button";

export const IconButton = ({ className, size, ...props }) => {
    let iconSize;

    switch (size) {
        case "small":
        case "medium":
            iconSize = "sm";

            break;
        case "large":
        default:
            iconSize = undefined;
    }

    return (
        <Button
            className={classNames("!p-0", {
                "w-[24px] h-[24px]": size === "small",
                "w-[32px] h-[32px]": !size || size === "medium",
                "w-[38px] h-[38px]": size === "large",
            })}
            iconProps={{
                size: iconSize,
            }}
            {...props}
        />
    );
};
