import { useEffect, useState } from "react";
import { Select } from "../Select";
import apiHelper from "../../helpers/api.helper";
import { useTranslation } from "react-i18next";

export const CountrySelect = ({ value, onChange, ...props }) => {
    const { t } = useTranslation();

    const [loadedOptions, setLoadedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchOptions = async () => {
        setIsLoading(true);

        try {
            const countriesResponse = await apiHelper.doApiCall(
                "GET",
                "/countries",
                {},
                false
            );

            if (countriesResponse.status === 200) {
                setLoadedOptions(
                    countriesResponse.data.data.map((country) => ({
                        label: t(`COUNTRY_${country.toUpperCase()}`),
                        value: country,
                    }))
                );
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    return (
        <Select
            options={loadedOptions}
            value={value}
            onChange={onChange}
            isLoading={isLoading}
            multi
            sort
            {...props}
        />
    );
};
