import classNames from "classnames";
import { Button } from "../../Button";

export const Links = ({ links }) => {
    const renderLinks = (links || []).map((v) => {
        return (
            <a href={v.link} target="_blank" className="block">
                <Button variant="secondary" className="w-full">
                    {v.text}
                </Button>
            </a>
        );
    });

    return <div className="grow space-y-2">{renderLinks}</div>;
};
