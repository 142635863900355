import { Link } from "react-router-dom";
import { Button } from "./Button";
import { Icon } from "./Icon";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "./Loader";
import { useEffect } from "react";
import classNames from "classnames";

export const Page = ({
    title,
    children,
    parentPage,
    parentPageTitle,
    rightContent,
    onBack,
    isLoading,
}) => {
    useEffect(() => {
        if (window.location.hash) {
            const element = document.getElementById(
                window.location.hash.replace("#", "")
            );

            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            document.body.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <div>
            <div className="max-w-screen-xl mx-auto px-4 pb-4">
                {(!!title || !!parentPage || !!rightContent) && (
                    <div className="flex items-center mb-4 w-full">
                        <div className="grow flex items-center shrink w-full overflow-hidden pr-2">
                            {(!!parentPage || parentPageTitle || !!onBack) && (
                                <Link to={parentPage} className="mr-3">
                                    <div
                                        className="flex items-center"
                                        onClick={onBack}
                                    >
                                        <Icon
                                            icon={faChevronLeft}
                                            size="sm"
                                            className="text-white text-opacity-40 pr-2 py-2"
                                        />
                                        {!!parentPageTitle && (
                                            <h2 className="inline-block text-base text-tertiary font-normal truncate whitespace-nowrap w-full">
                                                {parentPageTitle}
                                            </h2>
                                        )}
                                    </div>
                                </Link>
                            )}
                            {isLoading && (
                                <div className="grow">
                                    <Loader className="h-8 w-full lg:w-2/3" />
                                </div>
                            )}
                            {!!title && !isLoading && (
                                <h2
                                    className={classNames(
                                        "inline-block text-primary truncate whitespace-nowrap w-full",
                                        {
                                            "text-base font-bold":
                                                parentPageTitle,
                                            "text-2xl font-black":
                                                !parentPageTitle,
                                        }
                                    )}
                                >
                                    {title}
                                </h2>
                            )}
                        </div>
                        {!!rightContent && !isLoading && (
                            <div className="shrink-0">{rightContent}</div>
                        )}
                    </div>
                )}
                <div>{children}</div>
            </div>
        </div>
    );
};
