import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
    faUpDownLeftRight,
    faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../Icon";
import { useDrop } from "react-dnd";
import { useContext, useState } from "react";
import { sortStageValues, StageRenderer } from "./StageRenderer";
import { StageEditorSidebar } from "./StageEditorSidebar";
import { StageEditorContext } from "./StageEditorContext";
import { Card } from "../Card";

export const StageEditor = ({ advertiser, className, onChange, ...props }) => {
    const { t } = useTranslation();
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);

    const [sidebarExpanded, setSidebarExpanded] = useState(false);

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: "sidebar-element",
            drop: (config) => {
                const nextId =
                    advertiser.stageConfig.length > 0
                        ? Math.max(...advertiser.stageConfig.map((v) => v.id)) +
                          1
                        : 0;
                const nextSort =
                    advertiser.stageConfig.length > 0
                        ? Math.max(
                              ...advertiser.stageConfig.map((v) => v.sort)
                          ) + 1
                        : 1;

                const newItem = {
                    id: nextId,
                    type: config.type,
                    sort: nextSort,
                    props: config.defaultProps || {},
                };

                onChange([...advertiser.stageConfig, newItem]);

                dispatch({ type: "SET_CURRENT_ELEMENT", payload: newItem });

                return { name: "stage-editor" };
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [advertiser.stageConfig, onChange]
    );

    const sortedValue = (advertiser.stageConfig || []).sort(sortStageValues);

    return (
        <div className={classNames(className)}>
            <Card className="!p-2">
                <div
                    className={classNames(
                        "rounded-lg flex bg-opacity-5 overflow-hidden"
                    )}
                >
                    <div
                        // ref={dropRef}
                        className={classNames(
                            "grow-0 box-border self-stretch pr-4 w-full shrink",
                            {
                                "hidden sm:block": sidebarExpanded,
                            }
                        )}
                    >
                        {!advertiser.stageConfig?.length ? (
                            <div
                                className={classNames(
                                    "flex items-center rounded-lg flex-col justify-center h-full",
                                    {
                                        "bg-opacity-5": !isOver,
                                        "bg-opacity-10 outline-2 outline-cash outline-dashed":
                                            isOver,
                                    }
                                )}
                                ref={dropRef}
                            >
                                <Icon
                                    icon={faUpDownLeftRight}
                                    className="mb-4 !text-secondary"
                                    size="xl"
                                />
                                <p className="text-center px-16 text-sm text-secondary">
                                    {t("text.drag_and_drop")}
                                </p>
                            </div>
                        ) : (
                            <div>
                                <StageRenderer
                                    advertiser={advertiser}
                                    onElementClick={(v) => {
                                        dispatch({
                                            type: "SET_CURRENT_ELEMENT",
                                            payload: v,
                                        });
                                    }}
                                    isEditor
                                    onElementSort={(index, sort, element) => {
                                        let updatedValue = sortedValue.filter(
                                            (v) => v.id !== element.id
                                        ); // Clone the array to avoid mutating state directly

                                        // Remove the moved element from the array

                                        updatedValue.splice(
                                            index > element.sort
                                                ? index - 1
                                                : index,
                                            0,
                                            element
                                        );

                                        updatedValue = updatedValue.map(
                                            (v, i) => ({
                                                ...v,
                                                sort: i,
                                            })
                                        );

                                        onChange(updatedValue); // Call the callback with the updated array
                                        return { name: "stage-editor" };
                                    }}
                                    onElementDrop={(index, config) => {
                                        let nextId =
                                            advertiser.stageConfig.length > 0
                                                ? Math.max(
                                                      ...advertiser.stageConfig.map(
                                                          (v) => v.id
                                                      )
                                                  ) + 1
                                                : 0;

                                        const newItem = {
                                            id: nextId,
                                            type: config.type,
                                            props: config.defaultProps || {},
                                        };

                                        let updatedValue = [...sortedValue];

                                        // Remove the moved element from the array

                                        updatedValue.splice(index, 0, newItem);

                                        updatedValue = updatedValue.map(
                                            (v, i) => ({
                                                ...v,
                                                sort: i,
                                            })
                                        );

                                        onChange(updatedValue);

                                        dispatch({
                                            type: "SET_CURRENT_ELEMENT",
                                            payload: newItem,
                                        });

                                        return { name: "stage-editor" };
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={classNames("shrink-0", {
                            "sm:w-[300px] w-full": sidebarExpanded,
                        })}
                    >
                        <StageEditorSidebar
                            expanded={sidebarExpanded}
                            onExpand={() => setSidebarExpanded(true)}
                            onClose={() => {
                                setSidebarExpanded(false);

                                dispatch({
                                    type: "SET_CURRENT_ELEMENT",
                                    payload: null,
                                });
                            }}
                            onChange={onChange}
                            value={advertiser.stageConfig}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};
