import { Dialog } from "../Dialog";
import { useTranslation } from "react-i18next";
import { StageRenderer } from "../StageEditor/StageRenderer";
import { StageEditorContextProvider } from "../StageEditor/StageEditorContext";

export const StageDialog = ({ onClose, campaign, value, ...props }) => {
    const { t } = useTranslation();

    if (!campaign || !value?.length) {
        return null;
    }

    return (
        <Dialog
            cardClassName="mx-auto max-w-screen-md"
            title={campaign.advertiser.name}
            onClose={onClose}
            size="big"
            {...props}
        >
            <StageEditorContextProvider>
                <StageRenderer campaign={campaign} value={value} />
            </StageEditorContextProvider>
        </Dialog>
    );
};
