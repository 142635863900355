import classNames from "classnames";

export const Badge = ({ variant = "cash", className, children }) => {
    return (
        <div
            className={classNames(
                "px-2 py-1 font-semibold inline-block border border-opacity-20 rounded-md bg-opacity-20",
                {
                    "bg-green-500 text-green-500": variant === "positive",
                    "bg-red-500 text-red-500": variant === "negative",
                    "bg-orange-500 text-orange-500": variant === "warning",
                    "bg-gray-500 text-gray-500": variant === "muted",
                    "bg-cash text-cash border-cash": variant === "cash",
                },
                className
            )}
        >
            {children}
        </div>
    );
};
