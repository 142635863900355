export const stageEditorContextReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_CURRENT_ELEMENT":
            return {
                ...prevState,
                currentElement: action.payload,
            };
        default: {
            return prevState;
        }
    }
};
