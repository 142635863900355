import classNames from "classnames";
import { useEffect, useState } from "react";

export const FlashMessage = ({
    children,
    open,
    timeout,
    className,
    onTimeout = () => {},
    variant = "positive",
    ...props
}) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        let showTimeout;

        if (open) {
            setIsShown(true);

            showTimeout = setTimeout(() => {
                setIsShown(false);
            }, timeout);

            showTimeout = setTimeout(() => {
                onTimeout();
            }, timeout + 200);
        }

        return () => clearTimeout(showTimeout);
    }, [open]);

    if (!open) {
        return false;
    }

    return (
        <div className="fixed flex justify-center items-end w-full h-full top-0 left-0 pointer-events-none z-50 p-8">
            <div
                className={classNames(
                    "relative max-w-80 px-4 py-3 rounded-md pointer-events-auto transition-all text-white text-center text-base uppercase font-bold",
                    {
                        "bg-green-500": variant === "positive",
                        "bg-red-500": variant === "negative",
                        "opacity-0 translate-y-14": !isShown,
                        "opacity-100 translate-y-0": isShown,
                    },
                    className
                )}
                {...props}
            >
                {children}
            </div>
        </div>
    );
};
