import { getElementConfigFromType, stageStudioElements } from "./elements";

export const validateStageJson = (stageJson) => {
    let parsedStage = parseStage(stageJson);

    if (!parsedStage) {
        return false;
    }

    for (let i = 0; i < parsedStage.length; i++) {
        let element = parsedStage[i];
        let config = stageStudioElements[element.type];

        if (!config) {
            console.error(
                `No config found for element of type ${element.type}`
            );

            return false;
        }

        let elementKeys = Object.keys(element || {});

        for (let j = 0; j < elementKeys.length; j++) {
            let key = elementKeys[j];
            let value = element[key];

            switch (key) {
                case "id":
                case "sort":
                    if (isNaN(Number(value))) {
                        console.error(
                            `Invalid number value for ${key} with value ${value}`
                        );

                        return false;
                    }

                    break;
                case "type":
                    break;
                case "props":
                    let propKeys = Object.keys(value || {});

                    for (let k = 0; k < propKeys.length; k++) {
                        let propKey = propKeys[k];
                        let propValue = value[propKey];

                        console.log({ propKey, propValue, value });

                        let propConfig = config.arguments[propKey];

                        if (!validateProp(propKey, propValue, propConfig)) {
                            return false;
                        }
                    }

                    break;
                default:
                    console.error(
                        `Invalid element key ${key} with value ${value}`
                    );

                    return false;
            }
        }
    }

    return true;
};

const validateProp = (key, value, config) => {
    if (!config) {
        console.error(`No config given for prop ${key}`);

        return false;
    }

    console.log({ key, value, config });

    switch (config.type) {
        case "text":
        case "markdown":
            if (typeof value !== "string") {
                console.error(`Invalid type ${typeof value} for ${key}`);

                return false;
            }

            if (!!config.maxLength && value.length > config.maxLength) {
                console.error(`Length exceeded for ${key} with value ${value}`);

                return false;
            }

            break;
        case "select":
            if (!config.options?.[value]) {
                console.error(`Invalid option ${value} for ${key}`);

                return false;
            }

            break;
        case "image":
            // TODO: Validate images
            break;
        case "mvp":
            if (!Array.isArray(value)) {
                console.error(
                    `Value is not an array for MVP ${key} with value ${JSON.stringify(
                        value
                    )}`
                );

                return false;
            }

            if (!!config.maxEntries && value.length > config.maxEntries) {
                console.error(
                    `Max entries exceeded for MVP ${key} with value ${JSON.stringify(
                        value
                    )}`
                );

                return false;
            }

            for (let i = 0; i < value.length; i++) {
                let element = value[i];

                let keys = Object.keys(element || {});

                for (let j = 0; j < keys.length; j++) {
                    let mvpKey = keys[j];
                    let mvpValue = element[mvpKey];
                    let mvpConfig = config.arguments[mvpKey];

                    if (!mvpConfig) {
                        console.error(`MVP config not found for ${key}`);

                        return false;
                    }

                    if (!validateProp(mvpKey, mvpValue, mvpConfig)) {
                        console.error(
                            `Failed to validate ${mvpKey} with value ${mvpValue}`
                        );

                        return false;
                    }
                }
            }

            break;
        default:
            console.error(`Invalaid prop type ${config.type}`);

            return false;
    }

    return true;
};

const parseStage = (stageJson) => {
    try {
        let parsedStage = JSON.parse(stageJson);

        return parsedStage;
    } catch (err) {
        console.error("Failed to parse stage json", err);

        return null;
    }
};

export const getStageImageFiles = (stage) => {
    let files = [];

    if (!stage?.length) {
        return files;
    }

    stage.forEach((element) => {
        let elementConfig = getElementConfigFromType(element.type);

        Object.entries(element.props).forEach((key, entry) => {});
    });
};

const getStageImageFilesFromElement = (element) => {
    let elementConfig = getElementConfigFromType(element.type);

    let files = [];

    if (!elementConfig) {
        return files;
    }

    Object.entries(element.props).forEach((key, entry) => {
        let argConfig = elementConfig.arguments[key];

        if (!argConfig) {
            console.error(`Argument config for ${key} not found`);

            return;
        }

        if (argConfig.type === "image" && entry.file) {
            files.push([{}]);
        }
    });
};
