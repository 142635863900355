import { useEffect, useState } from "react";
import { Select } from "../Select";
import apiHelper from "../../helpers/api.helper";
import { useTranslation } from "react-i18next";

export const InterestSelect = ({ value, onChange, ...props }) => {
    const { t } = useTranslation();

    const [loadedOptions, setLoadedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchOptions = async () => {
        setIsLoading(true);

        try {
            const interestsResponse = await apiHelper.doApiCall(
                "GET",
                "/interests",
                {},
                false
            );

            if (interestsResponse.status === 200) {
                setLoadedOptions(
                    interestsResponse.data.data.map((interest) => ({
                        label: t(`INTEREST_${interest.toUpperCase()}`),
                        value: interest,
                    }))
                );
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    return (
        <Select
            options={loadedOptions}
            value={value}
            onChange={onChange}
            max={5}
            isLoading={isLoading}
            multi
            sort
            error={
                value?.length < 1 &&
                "You must have at least one topic for your advertisement"
            }
            {...props}
        />
    );
};
