import { Button } from "../../Button";
import { Headline } from "./Headline";

export const Hero = ({ title, text, image, buttonText, buttonLink }) => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 items-center">
            <div className="col-span-3">
                <Headline size="extraLarge" title={title} />
                <p className="w-3/4 text-secondary">{text}</p>
                {!!buttonText && !!buttonLink && (
                    <div className="mt-4">
                        <a href={buttonLink} target="_blank">
                            <Button>{buttonText}</Button>
                        </a>
                    </div>
                )}
            </div>
            {!!image && (
                <div className="col-span-2">
                    <img src={image.blob || image} alt="Hero image" />
                </div>
            )}
        </div>
    );
};
