import { useContext } from "react";
import { Hero } from "./elements/Hero";
import { Paragraph } from "./elements/Paragraph";
import classNames from "classnames";
import { StageEditorContext } from "./StageEditorContext";
import { Headline } from "./elements/Headline";
import { Icon } from "../Icon";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { useDrag } from "react-dnd";
import { Links } from "./elements/Links";
import { ProductSlider } from "./elements/ProductSlider";

const keyElementMap = {
    "hero": Hero,
    "paragraph": Paragraph,
    "headline": Headline,
    "links": Links,
    "productSlider": ProductSlider,
};

export const StageElement = ({
    element,
    onClick,
    draggable,
    isEditor,
    ...props
}) => {
    const [stageEditorContext] = useContext(StageEditorContext);

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: "element",
            item: element,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [element]
    );

    if (!element) {
        return null;
    }

    const ElementComponent = keyElementMap[element.type];

    if (!ElementComponent) {
        console.error(`Element component ${element.type} not found`);

        return null;
    }

    return (
        <div className="relative w-full" ref={draggable && dragRef}>
            <div
                className={classNames(
                    "grid items-center transition-all rounded-md",
                    {
                        "cursor-pointer hover:bg-white/5":
                            !!onClick &&
                            stageEditorContext.currentElement?.id !==
                                element.id,
                        "bg-white/10":
                            stageEditorContext.currentElement?.id ===
                            element.id,
                        "opacity-40": isDragging,
                        "grid-cols-[auto_1fr]": draggable,
                        "grid-cols-1": !draggable,
                        "p-2": isEditor,
                    }
                )}
                onClick={() => !!onClick && onClick(element)}
                {...props}
            >
                {draggable && (
                    <Icon
                        icon={faGripVertical}
                        size="sm"
                        className="!text-tertiary mr-4 ml-1"
                    />
                )}
                <div className="w-full overflow-hidden">
                    <ElementComponent {...(element.props || {})} />
                </div>
            </div>
        </div>
    );
};
