import classNames from "classnames";

export const AdvertiserLogo = ({
    advertiser,
    className,
    size = "medium",
    ...props
}) => {
    return (
        <a
            target={advertiser?.websiteUrl ? "_blank" : "_self"}
            href={advertiser?.websiteUrl || "#"}
            rel="noreferrer"
            key={`advertiser-${advertiser?.uuid}`}
        >
            {!!advertiser?.logoUrl ? (
                <img
                    src={advertiser?.logoUrl}
                    className={classNames(
                        {
                            "h-8": ["small", "medium"].includes(size),
                            "h-12": size === "large",
                        },
                        className
                    )}
                    alt={advertiser?.name}
                />
            ) : (
                <div
                    className={classNames(
                        "bg-white/5 rounded-md sm:hover:bg-white/10 active:bg-white/15",
                        {
                            "p-2 max-w-12 sm:max-w-36": [
                                "small",
                                "medium",
                            ].includes(size),
                            "py-2 px-4": size === "large",
                        },
                        className
                    )}
                >
                    <span
                        className={classNames("block text-secondary truncate", {
                            "text-xs": size === "small",
                            "text-sm": size === "medium",
                            "text-lg": size === "large",
                        })}
                    >
                        {advertiser?.name}
                    </span>
                </div>
            )}
        </a>
    );
};
