import { useNavigate, useParams } from "react-router";
import { Page } from "../Page";
import { useContext, useEffect, useRef, useState } from "react";
import { VideoInput } from "../VideoInput";
import { campaignUtils } from "../../utils/campaignUtils";
import { LimitMeter, CampaignStatusBadge } from "../cards/CampaignCard";
import { Card, Field, FieldContainer } from "../Card";
import { Button } from "../Button";
import { CampaignInsights } from "../CampaignInsights";
import { Message } from "../Message";
import { WatchAdDialog } from "../dialogs/WatchAdDialog";
import useQuestion from "../../hooks/useQuestion";
import { UserContext } from "../context/UserContext";
import { FlashContext } from "../context/FlashContext";
import { LimitDialog } from "../dialogs/LimitDialog";
import { Popover } from "../Popover";
import {
    faEarthEurope,
    faEllipsis,
    faGlobe,
    faPersonBooth,
    faTags,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { PublishDialog } from "../dialogs/PublishDialog";
import { Tabs } from "../Tabs";
import { CampaignWebsiteTab } from "../tabs/CampaignWebsiteTab";
import { CampaignTagsTab } from "../tabs/CampaignTagsTab";
import { CampaignCountriesTab } from "../tabs/CampaignCountriesTab";
import { PriceFormatter } from "../../helpers/number.helper";
import { getVideoError } from "../dialogs/NewCampaignDialog";
import { VideoUpload } from "../VideoUpload";
import { Loader } from "../Loader";
import { VisitWebsiteDialog } from "../dialogs/VisitWebsiteDialog";
import { StageStudioTab } from "../tabs/StageStudioTab";

export const CampaignPage = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { question, QuestionDialog } = useQuestion();
    const { t } = useTranslation();

    const [userContext] = useContext(UserContext);
    const [flashContext, flashDispatch] = useContext(FlashContext);

    const [campaign, setCampaign] = useState(null);
    const [asset, setAsset] = useState({ secure_url: campaign?.videoUrl });
    const [limit, setLimit] = useState(campaign?.limit || 0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [isLoadingPause, setIsLoadingPause] = useState(false);
    const [isLoadingResume, setIsLoadingResume] = useState(false);
    const [isLoadingDecline, setIsLoadingDecline] = useState(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isWebsitePreviewOpen, setIsWebsitePreviewOpen] = useState(false);
    const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
    const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState("website");

    useEffect(() => {
        fetchRaffle();
    }, [uuid]);

    useEffect(() => {
        init();
    }, [campaign]);

    const fetchRaffle = async () => {
        if (!uuid) {
            return;
        }

        setIsLoading(true);

        try {
            const campaignResponse = await campaignUtils.get(uuid);

            if (campaignResponse.status === 200) {
                setCampaign(campaignResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const init = () => {
        setAsset({
            secure_url: campaign?.videoUrl,
        });
        setLimit(campaign?.limit || 0);
    };

    useEffect(() => {
        if (asset?.public_id) {
            handleSave();
        }
    }, [asset?.public_id]);

    const handlePause = async () => {
        if (campaign?.status !== 3) {
            return;
        }

        const confirmation = await question(
            t("title.pause_campaign"),
            t("question.pause_campaign"),
            t("button.pause"),
            t("button.cancel")
        );

        if (!confirmation) {
            return;
        }

        setIsLoadingPause(true);

        try {
            const pauseResponse = await campaignUtils.pause(campaign?.uuid);

            if (pauseResponse.status === 200) {
                setCampaign(pauseResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingPause(false);
        }

        setIsLoadingPause(false);
    };

    const handleResume = async () => {
        if (campaign?.status !== 4) {
            return;
        }

        const confirmation = await question(
            t("title.resume_campaign"),
            t("question.resume_campaign"),
            t("button.resume"),
            t("button.cancel")
        );

        if (!confirmation) {
            return;
        }

        setIsLoadingResume(true);

        try {
            const resumeResponse = await campaignUtils.resume(campaign?.uuid);

            if (resumeResponse.status === 200) {
                setCampaign(resumeResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingResume(false);
        }

        setIsLoadingResume(false);
    };

    const handleDelete = async () => {
        const confirmation = await question(
            t("title.delete_campaign"),
            t("question.delete_campaign"),
            t("button.delete"),
            t("button.cancel"),
            "destructive"
        );

        if (!confirmation) {
            return;
        }

        setIsLoadingDelete(true);

        try {
            const deleteResponse = await campaignUtils.delete(campaign?.uuid);

            if (deleteResponse.status === 204) {
                setCampaign(deleteResponse.data.data);

                flashDispatch({
                    type: "SET_FLASH",
                    payload: {
                        message: t("SUCCESS_DELETE_CAMPAIGN"),
                        variant: "positive",
                    },
                });

                navigate("/play/account#campaigns");
            } else {
                flashDispatch({
                    type: "SET_FLASH",
                    payload: {
                        message: t("ERROR_DELETE"),
                        variant: "negative",
                    },
                });
            }
        } catch (err) {
            console.error(err);
            setIsLoadingDelete(false);
        }

        setIsLoadingDelete(false);
    };

    const handleApprove = async () => {
        if (campaign?.status !== 1) {
            return;
        }

        const confirmation = await question(
            "Approve campaign",
            "Are you sure you want to take this campaign live?",
            "Approve",
            "Cancel"
        );

        if (!confirmation) {
            return;
        }

        setIsLoadingApprove(true);

        try {
            const approveResponse = await campaignUtils.approve(campaign?.uuid);

            if (approveResponse.status === 200) {
                setCampaign(approveResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingApprove(false);
        }

        setIsLoadingApprove(false);
    };

    const handleDecline = async () => {
        if (campaign?.status !== 1) {
            return;
        }

        const reason = prompt("Provide a reason for declination");

        if (!reason) {
            return;
        }

        setIsLoadingDecline(true);

        try {
            const declineResponse = await campaignUtils.decline(
                campaign?.uuid,
                reason
            );

            if (declineResponse.status === 200) {
                setCampaign(declineResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingDecline(false);
        }

        setIsLoadingDecline(false);
    };

    const handleSave = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!validate()) {
            return;
        }

        setIsLoadingSave(true);

        const updatePayload = {
            assetId: asset?.public_id,
            limit,
        };

        try {
            const updateResponse = await campaignUtils.update(
                campaign?.uuid,
                updatePayload
            );

            if (updateResponse.status === 200) {
                setCampaign(updateResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingSave(false);
        }

        setIsLoadingSave(false);
    };

    const validate = () => {
        if (!asset?.asset_id && limit === campaign?.limit) {
            return false;
        }

        return true;
    };

    const cpc = getCPCByDuration(asset?.duration || campaign?.videoDuration);
    const cpwc = getCPWCByDuration(campaign?.websiteDuration);

    return (
        <Page
            title={t("title.manage_campaign")}
            onBack={() => navigate(-1)}
            parentPageTitle={t("title.my_account")}
            isLoading={isLoading}
            rightContent={
                <div className="flex">
                    <Popover
                        wrapperClassName="inline-block"
                        button={
                            <Button
                                icon={faEllipsis}
                                variant="secondary"
                                size="small"
                            />
                        }
                    >
                        <Button
                            variant="destructive"
                            className="w-full"
                            onClick={handleDelete}
                        >
                            {t("button.delete")}
                        </Button>
                    </Popover>
                </div>
            }
        >
            {isLoading || !campaign ? (
                <div className="space-y-8 mb-8">
                    <div className="space-y-4">
                        <Loader duration="6s" className="!h-6 w-3/4" />
                        <Loader duration="6.2s" className="!h-20" />
                        <Loader duration="6.4s" className="!h-20" />
                    </div>
                    <div className="space-y-4">
                        <Loader duration="6.6s" className="!h-6 w-3/4" />
                        <Loader duration="6.8s" className="!h-20" />
                        <Loader duration="7s" className="!h-20" />
                    </div>
                    <div className="space-y-4">
                        <Loader duration="7.2s" className="!h-6 w-3/4" />
                        <Loader duration="7.4s" className="!h-20" />
                        <Loader duration="7.6s" className="!h-20" />
                    </div>
                </div>
            ) : (
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-16 gap-4 mb-8">
                        <div className="md:col-span-5">
                            <Card className="h-full">
                                <form onSubmit={handleSave}>
                                    <VideoUpload
                                        value={asset}
                                        onUpload={(v) => {
                                            setAsset(v);
                                        }}
                                        error={getVideoError(t, asset)}
                                        publicId={campaign?.uuid}
                                        question={
                                            campaign?.status !== 0 &&
                                            t("text.campaign_to_draft")
                                        }
                                        questionTitle={t("title.save_campaign")}
                                        questionVariant="secondaryNegative"
                                        questionConfirmLabel={t("button.save")}
                                        questionCancelLabel={t("button.cancel")}
                                        className="mb-4"
                                    />
                                    <FieldContainer className="gap-4 mb-8">
                                        <div className="grid grid-cols-[1fr_auto] items-center xxs:col-span-2">
                                            <Field label={t("label.status")}>
                                                <CampaignStatusBadge
                                                    campaign={campaign}
                                                />
                                            </Field>
                                            {(campaign?.status === 0 ||
                                                campaign.status === 5) && (
                                                <Button
                                                    onClick={() =>
                                                        setIsPublishDialogOpen(
                                                            true
                                                        )
                                                    }
                                                    disabled={
                                                        (campaign?.status !==
                                                            0 &&
                                                            campaign.status !==
                                                                5) ||
                                                        !campaign.assetId
                                                    }
                                                    isLoading={isLoadingPublish}
                                                >
                                                    {t("button.publish")}
                                                </Button>
                                            )}
                                            {campaign?.status === 3 && (
                                                <Button
                                                    variant="secondary"
                                                    onClick={handlePause}
                                                    isLoading={isLoadingPause}
                                                >
                                                    {t("button.pause")}
                                                </Button>
                                            )}
                                            {campaign?.status === 4 && (
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleResume}
                                                    isLoading={isLoadingResume}
                                                >
                                                    {t("button.resume")}
                                                </Button>
                                            )}
                                            <CampaignStatusMessage
                                                campaign={campaign}
                                                className="mt-2 col-span-2"
                                                isLoadingApprove={
                                                    isLoadingApprove
                                                }
                                                isLoadingDecline={
                                                    isLoadingDecline
                                                }
                                                onApprove={handleApprove}
                                                onDecline={handleDecline}
                                            />
                                        </div>
                                        {campaign.watches > 0 && (
                                            <Field label={t("label.watches")}>
                                                {campaign.watches}
                                            </Field>
                                        )}
                                        {campaign.websiteClicks > 0 && (
                                            <Field
                                                label={t(
                                                    "label.website_watches"
                                                )}
                                            >
                                                {campaign.websiteClicks}
                                            </Field>
                                        )}
                                        {!!cpc && (
                                            <Field
                                                label={t("label.price")}
                                                className="xxs:col-span-2"
                                            >
                                                <div>
                                                    {t("text.per_watch", {
                                                        value: PriceFormatter.format(
                                                            cpc
                                                        ),
                                                    })}
                                                </div>
                                                {!!cpwc &&
                                                    !!campaign?.websiteUrl &&
                                                    t(
                                                        "text.per_website_watch",
                                                        {
                                                            value: PriceFormatter.format(
                                                                cpwc
                                                            ),
                                                        }
                                                    )}
                                            </Field>
                                        )}
                                        <div className="xxs:col-span-2 flex items-center">
                                            <Field
                                                label="Limit"
                                                className="grow mr-4"
                                            >
                                                {!!limit ? (
                                                    <LimitMeter
                                                        limit={limit}
                                                        watches={
                                                            campaign.watches
                                                        }
                                                    />
                                                ) : (
                                                    <span className="text-secondary">
                                                        {t("text.unlimited")}
                                                    </span>
                                                )}
                                            </Field>
                                            <Button
                                                onClick={() =>
                                                    setIsLimitDialogOpen(true)
                                                }
                                                variant="secondary"
                                            >
                                                {t("button.set")}
                                            </Button>
                                        </div>
                                    </FieldContainer>
                                    <div className="flex justify-end">
                                        <Button
                                            className="mr-2 hidden sm:inline-block"
                                            variant="secondary"
                                            size="small"
                                            onClick={() =>
                                                setIsPreviewOpen(true)
                                            }
                                        >
                                            {t("button.preview")}
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={!validate()}
                                        >
                                            {t("button.save")}
                                        </Button>
                                    </div>
                                </form>
                            </Card>
                        </div>
                        <div className="md:col-span-11">
                            <Tabs
                                currentTab={currentTab}
                                onTabChange={(v) => setCurrentTab(v)}
                                className="h-full"
                            >
                                <StageStudioTab
                                    onUpdate={(v) => setCampaign(v)}
                                    campaign={campaign}
                                    tabId="stage-studio"
                                    title={t("title.stage_studio")}
                                    icon={faPersonBooth}
                                />
                                <CampaignWebsiteTab
                                    onUpdate={(v) => setCampaign(v)}
                                    campaign={campaign}
                                    tabId="website"
                                    title={t("title.website")}
                                    onPreview={() =>
                                        setIsWebsitePreviewOpen(true)
                                    }
                                    isActive={
                                        !!campaign?.websiteUrl &&
                                        campaign.status === 3
                                    }
                                    icon={faGlobe}
                                />
                                <CampaignTagsTab
                                    onUpdate={(v) => setCampaign(v)}
                                    campaign={campaign}
                                    tabId="topics"
                                    title={t("title.tags")}
                                    icon={faTags}
                                />
                                <CampaignCountriesTab
                                    onUpdate={(v) => setCampaign(v)}
                                    campaign={campaign}
                                    tabId="countries"
                                    title={t("title.countries")}
                                    icon={faEarthEurope}
                                />
                            </Tabs>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-xl font-bold mb-4">
                            {t("title.insights")}
                        </h3>
                        <CampaignInsights campaign={campaign} />
                    </div>
                </div>
            )}
            <WatchAdDialog
                open={isPreviewOpen}
                onCancel={() => setIsPreviewOpen(false)}
                onClaim={() => setIsPreviewOpen(false)}
                ad={{
                    campaign: {
                        ...campaign,
                        videoUrl: asset?.secure_url,
                    },
                }}
            />
            <VisitWebsiteDialog
                open={isWebsitePreviewOpen}
                ad={{
                    advertiser: userContext?.user?.advertiser,
                    campaign: {
                        ...campaign,
                        websiteUrl: campaign?.websiteUrl,
                        description: campaign?.description,
                    },
                }}
                onCancel={() => {
                    setIsWebsitePreviewOpen(false);
                }}
                onClaim={() => setIsWebsitePreviewOpen(false)}
                isPreview
            />
            <QuestionDialog />
            <LimitDialog
                open={isLimitDialogOpen}
                onClose={() => setIsLimitDialogOpen(false)}
                onSet={(v) => setLimit(v)}
                limit={limit}
                campaign={campaign}
            />
            <PublishDialog
                open={isPublishDialogOpen}
                onClose={() => setIsPublishDialogOpen(false)}
                campaign={campaign}
                onUpdate={(v) => setCampaign(v)}
            />
        </Page>
    );
};

export const getCPCByDuration = (duration) => {
    if (!duration) {
        return null;
    }

    if (duration < 10) {
        return 0.02;
    } else if (duration < 15) {
        return 0.03;
    } else if (duration < 20) {
        return 0.04;
    } else if (duration < 25) {
        return 0.05;
    } else if (duration < 30) {
        return 0.06;
    }
};

export const getCPWCByDuration = (duration) => {
    if (duration === 15) {
        return 0.2;
    } else if (duration === 30) {
        return 0.3;
    } else if (duration === 45) {
        return 0.4;
    } else if (duration === 60) {
        return 0.5;
    }

    return null;
};

const CampaignStatusMessage = ({
    campaign,
    className,
    isLoadingApprove,
    isLoadingDecline,
    onDecline,
    onApprove,
}) => {
    const { t } = useTranslation();
    const [userContext] = useContext(UserContext);

    switch (campaign.status) {
        case 0:
            return (
                <Message variant="neutral" className={className}>
                    {t("text.campaign_draft")}
                </Message>
            );
        case 1:
            return (
                <Message variant="blue" className={className}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t("text.campaign_waiting_for_approval"),
                        }}
                    />
                    {!!userContext?.user?.isAdmin && (
                        <div className="flex justify-end mt-2">
                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={onDecline}
                                isLoading={isLoadingDecline}
                            >
                                Decline
                            </Button>
                            <Button
                                variant="primary"
                                className="!text-black"
                                onClick={onApprove}
                                isLoading={isLoadingApprove}
                            >
                                Approve
                            </Button>
                        </div>
                    )}
                </Message>
            );
        case 2:
            return (
                <Message variant="negative" className={className}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t("text.campaign_declined", {
                                value: campaign?.declineReason,
                            }),
                        }}
                    />
                </Message>
            );
        case 5:
            return (
                <Message variant="negative" className={className}>
                    <p>{t("text.payment_failed")}</p>
                </Message>
            );
        default:
            break;
    }

    return null;
};
