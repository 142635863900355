import classNames from "classnames";
import { Logo } from "./Logo";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";
import { ReactComponent as VodorLogo } from "../images/vodor-logo.svg";
import { useContext, useState } from "react";
import { UserContext } from "./context/UserContext";

export const Footer = ({ className, ...props }) => {
    const { t, i18n } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const handleLanguageChange = (v) => {
        localStorage.setItem("ar-setting-language", v);

        dispatch({ type: "SET_LANGUAGE", payload: v });

        i18n.changeLanguage(v);
    };

    return (
        <div
            className={classNames("mx-auto max-w-screen-xl", className)}
            {...props}
        >
            <Link to="/">
                <Logo variant="white" className="opacity-30 grayscale mb-4" />
            </Link>
            <div className="grid grid-cols-2 sm:grid-cols-4 mb-8 gap-8">
                <div className="col-span-2 text-sm">
                    <h5 className="text-tertiary mb-1 text-sm">
                        {t("title.disclaimer")}
                    </h5>
                    <p className="text-secondary">{t("text.disclaimer")}</p>
                </div>
                <div className="text-sm">
                    <h5 className="text-tertiary mb-1 text-sm">
                        {t("title.legal")}
                    </h5>
                    <Link to="/imprint">
                        <p className="text-secondary mb-1">
                            {t("title.imprint")}
                        </p>
                    </Link>
                    <Link to="/terms-of-service">
                        <p className="text-secondary mb-1">
                            {t("title.terms_of_service")}
                        </p>
                    </Link>
                    <Link to="/data-privacy">
                        <p className="text-secondary">
                            {t("title.data_privacy")}
                        </p>
                    </Link>
                </div>
                <div className="text-sm">
                    <h5 className="text-tertiary mb-1 text-sm">
                        {t("title.contact")}
                    </h5>
                    <p className="text-secondary mb-2 block">
                        support@adroulette.net
                    </p>
                    <a
                        href="mailto:support@adroulette.net"
                        className="text-black"
                    >
                        <Button className="w-full" variant="secondary">
                            {t("button.send_email")}
                        </Button>
                    </a>
                </div>
                <div className="col-span-2 sm:col-span-4">
                    <h5 className="text-tertiary mb-1 text-sm">
                        {t("title.language")}
                    </h5>
                    {languages.map(([label, lang], index) => (
                        <span
                            key={`footer-language-lang-${lang}`}
                            className={classNames(
                                "mr-2 cursor-pointer hover:underline text-sm",
                                {
                                    "text-cash": lang === userContext?.language,
                                    "text-secondary":
                                        lang !== userContext?.language,
                                }
                            )}
                            onClick={() => handleLanguageChange(lang)}
                        >
                            {label}
                        </span>
                    ))}
                </div>
                <a
                    href="https://www.vodor.de"
                    target="_blank"
                    className="opacity-50 col-span-2 sm:col-span-4 grid grid-cols-[1fr_auto_auto] text-sm items-center"
                >
                    <span>Provided by vodor</span>
                    <VodorLogo className="text-white h-[12px] mr-1 inline-block" />
                    <span>{`© ${new Date().getFullYear()}`}</span>
                </a>
            </div>
        </div>
    );
};

export const languages = [
    ["EN", "en_gb"],
    ["DE", "de_de"],
];
