import { useEffect, useState } from "react";
import { Button } from "./Button";
import classNames from "classnames";
import { DialogFullscreen } from "./DialogFullscreen";
import { Card } from "./Card";
import { Circle } from "./Circle";
import { Icon } from "./Icon";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

export const Tabs = ({ children, currentTab, onTabChange, className }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (window.location.hash) {
            const localHashPanelId = window.location.hash.replace("#tab-", "");

            if (
                children.find((tab) => tab?.props?.tabId === localHashPanelId)
            ) {
                setIsDialogOpen(true);

                onTabChange(localHashPanelId);
            }
        } else {
            onTabChange(children?.[0]?.props?.tabId || "");
        }
    }, [window.location.hash]);

    if (children?.length < 1) {
        console.error(
            "Children are not an array, please provide multiple Tabs"
        );

        return null;
    }

    children = children.filter(Boolean);

    const currentTabComponent = children.find(
        (v) => v.props.tabId === currentTab
    );

    const renderTabs = children.map((tab) => {
        if (!tab?.props?.tabId) {
            return null;
        }

        return (
            <div
                className="block sm:inline-block"
                key={`tab-${tab.props.tabId}`}
            >
                <div className="hidden sm:block">
                    <Button
                        variant={
                            currentTab === tab.props.tabId
                                ? "secondary"
                                : "tertiary"
                        }
                        className="w-auto !rounded-b-none !border-b-0 inline-block"
                        onClick={() => onTabChange(tab.props.tabId)}
                    >
                        {tab.props.title}
                        {tab.props.isActive !== undefined && (
                            <Circle
                                className={
                                    tab.props.isActive
                                        ? "bg-cash ml-2"
                                        : "bg-tertiary ml-2"
                                }
                            />
                        )}
                        {tab.props.required && (
                            <Icon
                                className={
                                    tab.props.isActive
                                        ? "!text-cash ml-2"
                                        : "!text-red-500 ml-2"
                                }
                                icon={faAsterisk}
                            />
                        )}
                    </Button>
                </div>
                <div className="block sm:hidden mb-2 sm:mb-0">
                    <Button
                        variant={"secondary"}
                        className="w-full"
                        size="large"
                        onClick={() => {
                            onTabChange(tab.props.tabId);
                            setIsDialogOpen(true);
                        }}
                        grow
                        rightIcon={tab.props.icon}
                    >
                        {tab.props.title}
                        {tab.props.isActive !== undefined && (
                            <Circle
                                className={
                                    tab.props.isActive
                                        ? "bg-cash ml-2"
                                        : "bg-red-500 ml-2"
                                }
                            />
                        )}
                        {tab.props.required && (
                            <Icon
                                className={
                                    tab.props.isActive
                                        ? "!text-cash ml-2"
                                        : "!text-red-500 ml-2"
                                }
                                icon={faAsterisk}
                            />
                        )}
                    </Button>
                </div>
            </div>
        );
    });

    return (
        <div className={className}>
            <div>{renderTabs}</div>
            <div className="hidden sm:block">
                <Card className="rounded-tl-none">{currentTabComponent}</Card>
            </div>
            <div className="block sm:hidden">
                <DialogFullscreen
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    title={currentTabComponent?.props?.title}
                >
                    {currentTabComponent}
                </DialogFullscreen>
            </div>
        </div>
    );
};

export const Tab = ({ children, title, tabId }) => {
    return <div>{children}</div>;
};
