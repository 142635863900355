import { createContext, useContext, useEffect, useReducer } from "react";
import { applicationContextReducer } from "./ApplicationContext.reducer";
import { advertiserUtils } from "../../utils/advertiserUtils";
import { raffleUtils } from "../../utils/raffleUtils";
import { raffleUserUtils } from "../../utils/raffleUserUtils";
import { UserContext } from "./UserContext";

const initialState = {
    currentAdvertisers: [],
    raffles: [],
    wonRaffles: [],
    myRaffles: [],
    isLoading: true,
};

export const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {
    const [userContext] = useContext(UserContext);

    const [store, dispatch] = useReducer(
        applicationContextReducer,
        initialState
    );

    useEffect(() => {
        if (!userContext.user?.uuid) {
            return;
        }

        const fetchAdvertisers = async () => {
            try {
                const advertisersResponse = await advertiserUtils.getActive();

                if (advertisersResponse.status === 200) {
                    dispatch({
                        type: "SET_CURRENT_ADVERTISERS",
                        payload: advertisersResponse.data.data,
                    });
                } else {
                    console.error("Could not fetch advertisers");
                }
            } catch (err) {
                console.error(err);
            }
        };

        const fetchRaffles = async () => {
            try {
                const rafflesResponse = await raffleUtils.search({
                    filters: [
                        {
                            field: "raffle.endAt",
                            operator: ">",
                            value: new Date(),
                        },
                        {
                            field: "raffle.uuidWinner",
                            operator: "=",
                            value: null,
                        },
                    ],
                });

                if (rafflesResponse.status === 200) {
                    dispatch({
                        type: "SET_RAFFLES",
                        payload: rafflesResponse.data.data,
                    });
                } else {
                    console.error("Could not fetch advertisers");
                }
            } catch (err) {
                console.error(err);
            }
        };

        const fetchMyRaffles = async () => {
            try {
                const myRafflesResponse = await raffleUserUtils.search({
                    filters: [
                        {
                            field: "raffle_user.uuidUser",
                            operator: "=",
                            value: userContext.user.uuid,
                        },
                        {
                            field: "raffle.endAt",
                            operator: ">",
                            value: new Date(),
                        },
                    ],
                    extend: ["*"],
                });

                if (myRafflesResponse.status === 200) {
                    dispatch({
                        type: "SET_MY_RAFFLES",
                        payload: myRafflesResponse.data.data,
                    });
                } else {
                    console.error("Could not fetch advertisers");
                }
            } catch (err) {
                console.error(err);
            }
        };

        const fetchWonRaffles = async () => {
            try {
                const wonRafflesResponse = await raffleUserUtils.search({
                    filters: [
                        {
                            field: "raffle.uuidWinner",
                            operator: "=",
                            value: userContext.user.uuid,
                        },
                        {
                            field: "raffle.claimedAt",
                            operator: "=",
                            value: null,
                        },
                    ],
                    extend: ["*"],
                });

                if (wonRafflesResponse.status === 200) {
                    dispatch({
                        type: "SET_WON_RAFFLES",
                        payload: wonRafflesResponse.data.data,
                    });
                } else {
                    console.error("Could not fetch advertisers");
                }
            } catch (err) {
                console.error(err);
            }
        };

        dispatch({ type: "SET_IS_LOADING", payload: true });

        Promise.all([
            fetchAdvertisers(),
            fetchRaffles(),
            fetchMyRaffles(),
            fetchWonRaffles(),
        ]).then(() => {
            dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }, [userContext.user?.uuid]);

    return (
        <ApplicationContext.Provider value={[store, dispatch]}>
            {children}
        </ApplicationContext.Provider>
    );
};
