import { useNavigate, useParams } from "react-router";
import { Page } from "../Page";
import { useContext, useEffect, useState } from "react";
import { raffleUtils } from "../../utils/raffleUtils";
import { RaffleSpinner } from "../RaffleSpinner";
import { PriceFormatter } from "../../helpers/number.helper";
import { raffleUserUtils } from "../../utils/raffleUserUtils";
import { Button } from "../Button";
import {
    faChevronRight,
    faPlay,
    faShareNodes,
    faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../context/UserContext";
import { userUtils } from "../../utils/userUtils";
import { WatchAdDialog } from "../dialogs/WatchAdDialog";
import { Loader } from "../Loader";
import { Feature } from "../Feature";
import { useTranslation } from "react-i18next";
import { Card, Field, FieldContainer } from "../Card";
import { TagLabel } from "../TagLabel";
import { Message } from "../Message";
import { Textbox } from "../Textbox";
import { VisitWebsiteDialog } from "../dialogs/VisitWebsiteDialog";
import classNames from "classnames";
import { advertiserUtils } from "../../utils/advertiserUtils";
import { CountUp } from "../CountUp";

export const RafflePage = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const [raffle, setRaffle] = useState(null);
    const [raffleUser, setRaffleUser] = useState(null);
    const [displayWinner, setDisplayWinner] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [isWatchAdEnabled, setIsWatchAdEnabled] = useState(false);
    const [currentAd, setCurrentAd] = useState(false);
    const [currentAdTimestamps, setCurrentAdTimestamps] = useState([]);
    const [nextWatchProgress, setNextWatchProgress] = useState(0);
    const [isLoadingRaffleClaim, setIsLoadingRaffleClaim] = useState(false);
    const [isLoadingRafflePay, setIsLoadingRafflePay] = useState(false);
    const [reference, setReference] = useState("");
    const [isWebsiteDialogOpen, setIsWebsiteDialogOpen] = useState(false);
    const [advertisers, setAdvertisers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isAdLoading, setIsAdLoading] = useState(false);

    const fetchRaffle = async (triggerLoader = true) => {
        if (triggerLoader) {
            setIsLoading(true);
        }

        const raffleResponse = await raffleUtils.getRaffle(uuid);

        if (raffleResponse.status === 200) {
            setRaffle(raffleResponse.data.data);

            const raffleUserResponse = await raffleUserUtils.search({
                filters: [
                    {
                        field: "raffle_user.uuidRaffle",
                        operator: "=",
                        value: raffleResponse.data.data.uuid,
                    },
                    {
                        field: "raffle_user.uuidUser",
                        operator: "=",
                        value: userContext?.user?.uuid,
                    },
                ],
                extend: ["*"],
            });

            if (raffleUserResponse.status === 200) {
                setRaffleUser(raffleUserResponse.data.data[0]);
            }

            setIsLoading(false);

            return;
        }

        setIsLoading(false);

        navigate("/play");
    };

    const handleShare = () => {
        if ("share" in navigator) {
            navigator.share({
                title: raffle?.title,
                text: `Watch an ad and get the chance of winning ${PriceFormatter.format(
                    raffle?.ticketCount * raffle?.ticketValue
                )}!`,
                url: `${window.location.href}`,
            });
        }
    };

    useEffect(() => {
        if (uuid && userContext?.user?.uuid) {
            fetchRaffle();
        }
    }, [uuid, userContext?.user?.uuid]);

    useEffect(() => {
        let refreshInterval;

        if (!raffle?.idWinner) {
            refreshInterval = setInterval(() => {
                fetchRaffle(false);
            }, 30000);
        } else {
            setTimeout(async () => {
                setDisplayWinner(true);

                if (raffle?.uuidWinner === userContext.user.uuid) {
                    // Refetch user to display new balance :)

                    const authResponse = await userUtils.auth();

                    if (authResponse.status === 200) {
                        dispatch({
                            type: "SET_USER",
                            payload: authResponse.data.data.user,
                        });

                        return;
                    }
                }
            }, [11000]);
        }

        return () => clearInterval(refreshInterval);
    }, [raffle]);

    const updateProgress = () => {
        const currentDate = new Date();
        const lastWatchedTime = new Date(raffleUser?.lastWatchedAt);
        const elapsedTime = currentDate.getTime() - lastWatchedTime.getTime();
        const timeLimit = 900000; // 15 minutes in milliseconds

        if (raffleUser?.lastWatchedAt && elapsedTime < timeLimit) {
            setIsWatchAdEnabled(false);
            const progress = (elapsedTime / timeLimit) * 100;
            setNextWatchProgress(progress);
        } else {
            setIsWatchAdEnabled(true);
        }
    };

    useEffect(() => {
        updateProgress();

        let progressInterval;

        progressInterval = setInterval(() => {
            updateProgress();
        }, 1000);

        return () => clearInterval(progressInterval);
    }, [raffleUser?.lastWatchedAt]);

    const fetchAdvertisers = async () => {
        try {
            const advertisersResponse = await advertiserUtils.getActive();

            if (advertisersResponse.status === 200) {
                setAdvertisers(advertisersResponse.data.data || []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchAdvertisers();
    }, []);

    const handleWatch = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsAdLoading(true);

        try {
            const watchResponse = await raffleUtils.watch(raffle.uuid);

            if (watchResponse.status === 200) {
                setCurrentAd({
                    ...watchResponse.data.data,
                    raffle,
                });
            }
        } catch (err) {
            console.error(err);

            setIsAdLoading(false);
        }

        setIsAdLoading(false);
    };

    const handleClaim = (data) => {
        if (!currentAd || !data) {
            return;
        }

        setRaffle(data.raffle);
        setRaffleUser(data);

        if (!!currentAd?.campaign?.websiteUrl) {
            setIsWebsiteDialogOpen(true);

            return;
        }

        setCurrentAd(null);
    };

    const handleRaffleClaim = async () => {
        setIsLoadingRaffleClaim(true);

        try {
            const raffleClaimResponse = await raffleUtils.claim(raffle.uuid);

            if (raffleClaimResponse.status === 200) {
                setRaffle(raffleClaimResponse.data.data);
            }

            setIsLoadingRaffleClaim(false);
        } catch (err) {
            console.error(err);

            setIsLoadingRaffleClaim(false);
        }
    };

    const handlePay = async (e) => {
        if (e) {
            e.preventDefault();
        }

        setIsLoadingRafflePay(true);

        try {
            const rafflePayResponse = await raffleUtils.pay(raffle.uuid, {
                referenceId: reference,
            });

            if (rafflePayResponse.status === 200) {
                setRaffle(rafflePayResponse.data.data);
            }

            setIsLoadingRafflePay(false);
        } catch (err) {
            console.error(err);

            setIsLoadingRafflePay(false);
        }
    };

    return (
        <Page
            title={`${raffle?.name}${
                raffle?.repetitionCount ? ` #${raffle.repetitionCount}` : ""
            }`}
            parentPage="/play"
            parentPageTitle={t("title.raffles")}
            isLoading={isLoading}
            rightContent={
                <Button
                    variant="secondary"
                    icon={faShareNodes}
                    className={"shrink-0"}
                    size="small"
                    onClick={handleShare}
                >
                    {t("button.share")}
                </Button>
            }
        >
            {isLoading ? (
                <Loader className="mb-4 h-36" />
            ) : (
                <div className="mb-8">
                    <RaffleSpinner raffle={raffle}>
                        <Button
                            variant="primary"
                            icon={faPlay}
                            disabled={!isWatchAdEnabled || !advertisers.length}
                            onClick={handleWatch}
                            isLoading={isAdLoading}
                            progress={
                                nextWatchProgress > 0 &&
                                Math.ceil(nextWatchProgress)
                            }
                        >
                            {t("button.watch")}
                        </Button>
                    </RaffleSpinner>
                </div>
            )}
            <div className="sm:max-w-screen-sm mx-auto">
                {isLoading ? (
                    <div className="text-center">
                        <div className="inline-block w-[64px] m-2">
                            <Loader className="w-full h-[24px] mb-2" />
                            <Loader className="w-full h-[14px]" />
                        </div>
                        <div className="inline-block w-[64px] m-2">
                            <Loader className="w-full h-[24px] mb-2" />
                            <Loader className="w-full h-[14px]" />
                        </div>
                        <div className="inline-block w-[64px] m-2">
                            <Loader className="w-full h-[24px] mb-2" />
                            <Loader className="w-full h-[14px]" />
                        </div>
                        <div className="inline-block w-[64px] m-2">
                            <Loader className="w-full h-[24px] mb-2" />
                            <Loader className="w-full h-[14px]" />
                        </div>
                        <div className="inline-block w-[64px] m-2">
                            <Loader className="w-full h-[24px] mb-2" />
                            <Loader className="w-full h-[14px]" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {raffle?.idWinner ? (
                            <div>
                                <h3
                                    className="text-2xl text-center font-bold mb-4"
                                    dangerouslySetInnerHTML={{
                                        __html: t("title.raffle_winner", {
                                            value:
                                                raffle?.idWinner ===
                                                userContext.user.id
                                                    ? "You"
                                                    : `#${raffle?.idWinner}`,
                                        }),
                                    }}
                                />
                                {!!raffle?.idWinner &&
                                    raffle?.idWinner !==
                                        userContext?.user?.id && (
                                        <p className="text-tertiary text-center">
                                            {t("text.better_luck_next_time")}
                                        </p>
                                    )}
                                {raffle?.idWinner === userContext.user.id &&
                                    !raffle.claimedAt && (
                                        <div className="text-center">
                                            <p
                                                className="text-secondary mb-4"
                                                dangerouslySetInnerHTML={{
                                                    __html: t(
                                                        "text.claim_prize",
                                                        {
                                                            value: PriceFormatter.format(
                                                                raffle?.ticketCount *
                                                                    raffle?.ticketValue
                                                            ),
                                                        }
                                                    ),
                                                }}
                                            />

                                            <Button
                                                variant="primary"
                                                onClick={handleRaffleClaim}
                                                isLoading={isLoadingRaffleClaim}
                                                disabled={
                                                    !userContext?.user
                                                        ?.cashOutType ||
                                                    !userContext?.user
                                                        ?.cashOutValue
                                                }
                                            >
                                                {t("button.claim_prize")}
                                            </Button>
                                            {(!userContext?.user?.cashOutType ||
                                                !userContext?.user
                                                    ?.cashOutValue) && (
                                                <div className="mt-2">
                                                    <p className="text-secondary">
                                                        {t(
                                                            "text.valid_bank_account_needed"
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                {!!raffle.claimedAt &&
                                    raffle?.idWinner ===
                                        userContext.user.id && (
                                        <Field label={t("label.status")}>
                                            <RaffleStatusBadge
                                                raffle={raffle}
                                                className="mb-2"
                                            />
                                            <RaffleStatusMessage
                                                raffle={raffle}
                                            />
                                        </Field>
                                    )}
                                {userContext?.user?.isAdmin &&
                                    raffle.uuidWinner && (
                                        <Card className="mt-8">
                                            <form onSubmit={handlePay}>
                                                <FieldContainer className="mb-4">
                                                    <Field
                                                        label="Cash out"
                                                        copyable
                                                        className="space-x-2"
                                                    >
                                                        <span className="text-secondary">
                                                            {
                                                                raffleUser?.user
                                                                    ?.cashOutType
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                raffleUser?.user
                                                                    ?.cashOutValue
                                                            }
                                                        </span>
                                                    </Field>
                                                    <Field
                                                        label="Amount"
                                                        className="font-bold"
                                                        copyable
                                                    >
                                                        {PriceFormatter.format(
                                                            raffle?.ticketCount *
                                                                raffle?.ticketValue
                                                        )}
                                                    </Field>
                                                    <Field
                                                        label="Raffle-ID"
                                                        copyable
                                                        className="col-span-2"
                                                    >
                                                        {raffle.uuid}
                                                    </Field>
                                                </FieldContainer>
                                                <Textbox
                                                    value={reference}
                                                    onChange={(e) =>
                                                        setReference(
                                                            e.target.value
                                                        )
                                                    }
                                                    label="Reference"
                                                    placeholder="Enter reference of payment"
                                                    size="large"
                                                    className="mb-4"
                                                    maxLength={255}
                                                />
                                                <div className="text-right">
                                                    <Button type="submit">
                                                        Submit
                                                    </Button>
                                                </div>
                                            </form>
                                        </Card>
                                    )}
                            </div>
                        ) : (
                            <div>
                                <div className="text-center mb-4">
                                    <div className="flex flex-col items-center mb-8">
                                        <Feature
                                            label={t("label.prize_pool")}
                                            className="mb-2"
                                            glowClassName={classNames(
                                                "!w-64 !h-8",
                                                {
                                                    "bg-yellow":
                                                        raffle.repetition ===
                                                        "yearly",
                                                    "bg-cash":
                                                        raffle.repetition !==
                                                        "yearly",
                                                }
                                            )}
                                        >
                                            <CountUp
                                                value={
                                                    raffle?.ticketCount *
                                                    raffle?.ticketValue
                                                }
                                                className={classNames("block", {
                                                    "text-cash":
                                                        raffle.repetition !==
                                                        "yearly",
                                                    "text-yellow":
                                                        raffle.repetition ===
                                                        "yearly",
                                                })}
                                            />
                                        </Feature>
                                    </div>
                                    <div className="inline-grid grid-cols-1 xxs:grid-cols-2 gap-4 xxs:gap-8 px-8">
                                        <Feature
                                            variant="secondary"
                                            label={t("label.tickets")}
                                        >
                                            {raffle?.ticketCount}
                                        </Feature>
                                        <Feature
                                            variant="secondary"
                                            label={t("label.participants")}
                                        >
                                            {raffle?.userCount}
                                        </Feature>
                                        {raffleUser?.tickets && (
                                            <Feature
                                                variant="secondary"
                                                label={t("label.your_tickets")}
                                            >
                                                {raffleUser?.tickets}
                                            </Feature>
                                        )}
                                        {raffleUser?.tickets && (
                                            <Feature
                                                variant="secondary"
                                                label={t("label.win_chance")}
                                            >
                                                {(
                                                    (raffleUser?.tickets /
                                                        raffle.ticketCount) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </Feature>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <WatchAdDialog
                open={currentAd}
                ad={currentAd}
                onCancel={() => setCurrentAd(null)}
                onClaim={handleClaim}
                timestamps={currentAdTimestamps}
                onTimestampsChange={(v) => setCurrentAdTimestamps(v)}
            />
            <VisitWebsiteDialog
                open={isWebsiteDialogOpen}
                ad={currentAd}
                onCancel={() => {
                    setCurrentAd(null);
                    setIsWebsiteDialogOpen(false);
                }}
                onClaim={() => setIsWebsiteDialogOpen(false)}
            />
        </Page>
    );
};

export const RaffleStatusBadge = ({ raffle, ...props }) => {
    const { t } = useTranslation();

    let variant = "neutral";
    let label = t("label.waiting_for_payment");

    if (raffle.payedAt) {
        variant = "positive";
        label = t("label.payment_confirmed");
    }

    return (
        <TagLabel variant={variant} {...props}>
            {label}
        </TagLabel>
    );
};

export const RaffleStatusMessage = ({ raffle, ...props }) => {
    const { t } = useTranslation();

    let variant = "neutral";
    let label = t("text.waiting_for_payment");

    if (raffle.payedAt) {
        variant = "positive";
        label = t("text.payment_confirmed");
    }

    return (
        <Message variant={variant} {...props}>
            {label}
        </Message>
    );
};
