import { Dialog } from "../Dialog";
import { useTranslation } from "react-i18next";
import { StageRenderer } from "../StageEditor/StageRenderer";
import { StageEditorContextProvider } from "../StageEditor/StageEditorContext";
import { DialogFullscreen } from "../DialogFullscreen";

export const StageDialog = ({ onClose, advertiser, ...props }) => {
    const { t } = useTranslation();

    if (!advertiser) {
        return null;
    }

    return (
        <DialogFullscreen
            cardClassName="mx-auto max-w-screen-md"
            title={advertiser.name}
            onClose={onClose}
            {...props}
        >
            <StageEditorContextProvider>
                <StageRenderer advertiser={advertiser} />
            </StageEditorContextProvider>
        </DialogFullscreen>
    );
};
