import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
    faUpDownLeftRight,
    faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../Icon";
import { useDrop } from "react-dnd";
import { useContext, useEffect, useState } from "react";
import { sortStageValues, StageRenderer } from "./StageRenderer";
import { StageEditorSidebar } from "./StageEditorSidebar";
import { StageEditorContext } from "./StageEditorContext";
import { Card } from "../Card";

export const StageEditor = ({
    campaign,
    value,
    className,
    onChange,
    isEditing,
    onEditExit,
    ...props
}) => {
    const { t } = useTranslation();
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: "sidebar-element",
            drop: (config) => {
                const nextId =
                    value?.length > 0
                        ? Math.max(...value.map((v) => v.id)) + 1
                        : 0;
                const nextSort =
                    value?.length > 0
                        ? Math.max(...value.map((v) => v.sort)) + 1
                        : 1;

                const newItem = {
                    id: nextId,
                    type: config.type,
                    sort: nextSort,
                    props: config.defaultProps || {},
                };

                onChange([...(value || []), newItem]);

                dispatch({ type: "SET_CURRENT_ELEMENT", payload: newItem });

                return { name: "stage-editor" };
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [value, onChange]
    );

    const sortedValue = (value || []).sort(sortStageValues);

    return (
        <div className={classNames(className)}>
            <Card className="!p-4">
                <div
                    className={classNames(
                        "grid sm:grid-cols-12 gap-4 rounded-lg bg-opacity-5 overflow-hidden w-full"
                    )}
                >
                    <div
                        // ref={dropRef}
                        className={classNames("box-border order-2 sm:order-1", {
                            "sm:col-span-7": isEditing,
                            "sm:col-span-12": !isEditing,
                        })}
                    >
                        {!value?.length ? (
                            <div
                                className={classNames(
                                    "flex items-center rounded-lg flex-col justify-center h-full",
                                    {
                                        "bg-opacity-5": !isOver,
                                        "bg-opacity-10 outline-2 outline-cash outline-dashed":
                                            isOver,
                                    }
                                )}
                                ref={dropRef}
                            >
                                <Icon
                                    icon={faUpDownLeftRight}
                                    className="mb-4 !text-secondary"
                                    size="xl"
                                />
                                <p className="text-center px-16 text-sm text-secondary">
                                    {t("text.drag_and_drop")}
                                </p>
                            </div>
                        ) : (
                            <div>
                                <StageRenderer
                                    value={value}
                                    campaign={campaign}
                                    onElementClick={(v) => {
                                        dispatch({
                                            type: "SET_CURRENT_ELEMENT",
                                            payload: v,
                                        });
                                    }}
                                    // Don't know why it's not working without undefined as fallback lol
                                    isEditor={isEditing || undefined}
                                    onElementSort={(index, sort, element) => {
                                        let updatedValue = sortedValue.filter(
                                            (v) => v.id !== element.id
                                        );

                                        updatedValue.splice(
                                            index > element.sort
                                                ? index - 1
                                                : index,
                                            0,
                                            element
                                        );

                                        updatedValue = updatedValue.map(
                                            (v, i) => ({
                                                ...v,
                                                sort: i,
                                            })
                                        );

                                        onChange(updatedValue);
                                        return { name: "stage-editor" };
                                    }}
                                    onElementDrop={(index, config) => {
                                        let nextId =
                                            value?.length > 0
                                                ? Math.max(
                                                      ...value.map((v) => v.id)
                                                  ) + 1
                                                : 0;

                                        const newItem = {
                                            id: nextId,
                                            type: config.type,
                                            props: config.defaultProps || {},
                                        };

                                        let updatedValue = [...sortedValue];

                                        // Remove the moved element from the array

                                        updatedValue.splice(index, 0, newItem);

                                        updatedValue = updatedValue.map(
                                            (v, i) => ({
                                                ...v,
                                                sort: i,
                                            })
                                        );

                                        onChange(updatedValue);

                                        dispatch({
                                            type: "SET_CURRENT_ELEMENT",
                                            payload: newItem,
                                        });

                                        return { name: "stage-editor" };
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {isEditing && (
                        <div className="sm:col-span-5 order-1 sm:order-2">
                            <StageEditorSidebar
                                onClose={() => {
                                    onEditExit(false);

                                    dispatch({
                                        type: "SET_CURRENT_ELEMENT",
                                        payload: null,
                                    });
                                }}
                                onChange={onChange}
                                value={value}
                            />
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};
