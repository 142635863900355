import {
    faAnchor,
    faAngleRight,
    faArrowLeft,
    faBars,
    faClose,
    faGreaterThan,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import { stageStudioElements } from "lib/src/stage/elements";
import { Button } from "../Button";
import { useContext, useEffect, useState } from "react";
import { StageEditorContext } from "./StageEditorContext";
import { SubPage, SubPages } from "../SubPages";
import classNames from "classnames";
import { StageEditorControls } from "./StageEditorControls";
import useQuestion from "../../hooks/useQuestion";
import { Card } from "../Card";
import { IconButton } from "../IconButton";

export const StageEditorSidebar = ({
    onClose = () => {},
    onChange,
    value = [],
    className,
}) => {
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const { QuestionDialog, question } = useQuestion();
    const { t } = useTranslation();

    const renderElementButtons = Object.keys(stageStudioElements).map((key) => {
        let elementConfig = stageStudioElements[key];

        const nextId =
            value.length > 0 ? Math.max(...value.map((v) => v.id)) + 1 : 0;
        const nextSort =
            value.length > 0 ? Math.max(...value.map((v) => v.sort)) + 1 : 1;

        const newItem = {
            id: nextId,
            type: key,
            sort: nextSort,
            props: elementConfig.defaultProps || {},
        };

        return (
            <StageEditorSidebarElementButton
                type={key}
                config={elementConfig}
                wrapperClassName="inline-block mr-2 mb-2 sm:mb-0 sm:mr-0"
                onClick={() => {
                    dispatch({ type: "SET_CURRENT_ELEMENT", payload: newItem });

                    onChange([...value, newItem]);
                }}
            />
        );
    });

    const currentElementConfig =
        stageStudioElements[stageEditorContext.currentElement?.type];

    const handleChange = (key, newValue) => {
        let newState = (value || []).map((v) => {
            if (v.id === stageEditorContext.currentElement.id) {
                return {
                    ...v,
                    props: {
                        ...(v.props || {}),
                        [key]: newValue,
                    },
                };
            }

            return v;
        });

        onChange(newState);
    };

    const handleDelete = async () => {
        const answer = await question(
            t("title.delete_stage_element"),
            t("question.delete_stage_element"),
            t("button.yes"),
            t("button.cancel"),
            "destructive"
        );

        if (!answer) {
            return;
        }

        let newState = (value || []).filter(
            (v) => v.id !== stageEditorContext.currentElement.id
        );

        dispatch({ type: "SET_CURRENT_ELEMENT", payload: null });

        onChange(newState);
    };

    return (
        <>
            <div
                className={classNames(
                    "transition-all rounded-lg space-y-2",
                    className
                )}
            >
                {!!stageEditorContext.currentElement && (
                    <div className="flex items-center mb-2">
                        <div className="grow">
                            <span>
                                <span
                                    className="font-bold text-tertiary cursor-pointer"
                                    onClick={() =>
                                        dispatch({
                                            type: "SET_CURRENT_ELEMENT",
                                            payload: null,
                                        })
                                    }
                                >
                                    <Icon
                                        icon={faArrowLeft}
                                        className="mr-1 !text-tertiary"
                                        size="sm"
                                    />
                                    {t("title.elements")}
                                </span>
                                <Icon
                                    icon={faAngleRight}
                                    size="xs"
                                    className="!text-tertiary mx-2"
                                />
                                <span className="font-bold">{`${t(
                                    currentElementConfig.label
                                )} #${
                                    stageEditorContext.currentElement.id
                                }`}</span>
                            </span>
                        </div>
                        <IconButton
                            icon={faTrash}
                            size="medium"
                            variant="secondaryNegative"
                            iconClassName="!text-red-500"
                            onClick={handleDelete}
                        />
                    </div>
                )}
                <div>
                    <SubPages
                        currentPage={
                            !!stageEditorContext.currentElement ? 1 : 0
                        }
                    >
                        <SubPage>
                            <div className="block sm:gap-2 sm:grid">
                                {renderElementButtons}
                            </div>
                        </SubPage>
                        <SubPage>
                            {!!stageEditorContext.currentElement &&
                                !!currentElementConfig && (
                                    <StageEditorControls
                                        elementConfig={currentElementConfig}
                                        value={
                                            (value || []).find(
                                                (v) =>
                                                    v.id ===
                                                    stageEditorContext
                                                        .currentElement.id
                                            ) || {}
                                        }
                                        onChange={handleChange}
                                    />
                                )}
                        </SubPage>
                    </SubPages>
                </div>
            </div>
            <QuestionDialog />
        </>
    );
};

const StageEditorSidebarElementButton = ({
    type,
    config,
    onClick,
    className,
    wrapperClassName,
}) => {
    const { t } = useTranslation();
    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: "sidebar-element",
        item: {
            type,
            ...config,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const opacity = isDragging ? 0.4 : 1;

    if (!config) {
        return null;
    }

    return (
        <div ref={dragRef} style={{ opacity }} className={wrapperClassName}>
            <Button
                variant="secondary"
                icon={faAnchor}
                grow
                iconProps={{ size: "lg", className: "mr-1" }}
                className={classNames("w-full", className)}
                onClick={onClick}
            >
                <div className="flex items-center">
                    <div className="text-left">
                        <span>{t(config.label)}</span>
                    </div>
                </div>
            </Button>
        </div>
    );
};
