import classNames from "classnames";

export const Textarea = ({
    value,
    onChange,
    label,
    description,
    placeholder,
    className,
    inputClassName,
    type = "text",
    ...props
}) => {
    return (
        <div className={className}>
            {!!label && (
                <label className="block mb-2 text-primary text-opacity-90 font-semibold">
                    {label}
                </label>
            )}
            <textarea
                className={classNames(
                    "p-2 bg-white/5 min-h-44 block rounded-md w-full outline-none text-white",
                    inputClassName
                )}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                {...props}
            />
            {!!description && (
                <p className="text-secondary mt-2">{description}</p>
            )}
        </div>
    );
};
