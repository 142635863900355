import {
    faAnchor,
    faAngleRight,
    faArrowLeft,
    faBars,
    faClose,
    faGreaterThan,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import { stageStudioElements } from "lib/src/stageStudio/elements";
import { Button } from "../Button";
import { useContext, useEffect, useState } from "react";
import { StageEditorContext } from "./StageEditorContext";
import { SubPage, SubPages } from "../SubPages";
import classNames from "classnames";
import { StageEditorControls } from "./StageEditorControls";
import useQuestion from "../../hooks/useQuestion";
import { Card } from "../Card";

export const StageEditorSidebar = ({
    expanded,
    onExpand = () => {},
    onClose = () => {},
    onChange,
    value = [],
    className,
}) => {
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const { QuestionDialog, question } = useQuestion();
    const { t } = useTranslation();

    useEffect(() => {
        if (stageEditorContext.currentElement) {
            onExpand(true);
        }
    }, [stageEditorContext.currentElement]);

    const renderElementButtons = Object.keys(stageStudioElements).map((key) => {
        let elementConfig = stageStudioElements[key];

        const nextId =
            value.length > 0 ? Math.max(...value.map((v) => v.id)) + 1 : 0;
        const nextSort =
            value.length > 0 ? Math.max(...value.map((v) => v.sort)) + 1 : 1;

        const newItem = {
            id: nextId,
            type: key,
            sort: nextSort,
            props: elementConfig.defaultProps || {},
        };

        return (
            <StageEditorSidebarElementButton
                type={key}
                config={elementConfig}
                onClick={() => {
                    dispatch({ type: "SET_CURRENT_ELEMENT", payload: newItem });

                    onChange([...value, newItem]);
                }}
            />
        );
    });

    const currentElementConfig =
        stageStudioElements[stageEditorContext.currentElement?.type];

    const handleChange = (key, newValue) => {
        let newState = (value || []).map((v) => {
            if (v.id === stageEditorContext.currentElement.id) {
                return {
                    ...v,
                    props: {
                        ...(v.props || {}),
                        [key]: newValue,
                    },
                };
            }

            return v;
        });

        onChange(newState);
    };

    const handleDelete = async () => {
        const answer = await question(
            t("title.delete_stage_element"),
            t("question.delete_stage_element"),
            t("button.yes"),
            t("button.cancel"),
            "destructive"
        );

        if (!answer) {
            return;
        }

        let newState = (value || []).filter(
            (v) => v.id !== stageEditorContext.currentElement.id
        );

        dispatch({ type: "SET_CURRENT_ELEMENT", payload: null });

        onChange(newState);
    };

    return (
        <>
            {expanded ? (
                <Card
                    className={classNames(
                        "transition-all !p-3 rounded-lg space-y-2",
                        {
                            "bg-white/5": expanded,
                        },
                        className
                    )}
                >
                    <div className="flex items-center mb-2">
                        {expanded && (
                            <div className="grow">
                                {!!stageEditorContext.currentElement &&
                                !!currentElementConfig ? (
                                    <span>
                                        <span
                                            className="font-bold text-tertiary cursor-pointer"
                                            onClick={() =>
                                                dispatch({
                                                    type: "SET_CURRENT_ELEMENT",
                                                    payload: null,
                                                })
                                            }
                                        >
                                            <Icon
                                                icon={faArrowLeft}
                                                className="mr-1 !text-tertiary"
                                                size="sm"
                                            />
                                            {t("title.elements")}
                                        </span>
                                        <Icon
                                            icon={faAngleRight}
                                            size="xs"
                                            className="!text-tertiary mx-2"
                                        />
                                        <span className="font-bold">{`${t(
                                            currentElementConfig.label
                                        )} #${
                                            stageEditorContext.currentElement.id
                                        }`}</span>
                                    </span>
                                ) : (
                                    <span className="font-bold">
                                        {t("title.elements")}
                                    </span>
                                )}
                            </div>
                        )}
                        <Button
                            variant="tertiary"
                            size="small"
                            icon={faClose}
                            onClick={() => onClose()}
                        />
                    </div>
                    <div>
                        <SubPages
                            currentPage={
                                !!stageEditorContext.currentElement ? 1 : 0
                            }
                        >
                            <SubPage>
                                <div className="grid gap-4">
                                    <div className="grid gap-2">
                                        {renderElementButtons}
                                    </div>
                                </div>
                            </SubPage>
                            <SubPage>
                                {!!stageEditorContext.currentElement &&
                                    !!currentElementConfig && (
                                        <div>
                                            <div className="flex justify-end mb-2">
                                                <Button
                                                    icon={faTrash}
                                                    size="extraSmall"
                                                    variant="secondaryNegative"
                                                    iconClassName="!text-red-500"
                                                    onClick={handleDelete}
                                                />
                                            </div>
                                            <StageEditorControls
                                                elementConfig={
                                                    currentElementConfig
                                                }
                                                value={
                                                    (value || []).find(
                                                        (v) =>
                                                            v.id ===
                                                            stageEditorContext
                                                                .currentElement
                                                                .id
                                                    ) || {}
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )}
                            </SubPage>
                        </SubPages>
                    </div>
                </Card>
            ) : (
                <Button
                    variant="tertiary"
                    icon={faBars}
                    onClick={() => onExpand()}
                />
            )}
            <QuestionDialog />
        </>
    );
};

const StageEditorSidebarElementButton = ({ type, config, onClick }) => {
    const { t } = useTranslation();
    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: "sidebar-element",
        item: {
            type,
            ...config,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const opacity = isDragging ? 0.4 : 1;

    if (!config) {
        return null;
    }

    const tokensPerEntry = Object.entries(config.arguments || {}).reduce(
        (prev, [key, entry]) =>
            entry.tokensPerEntry > 0 ? prev + entry.tokensPerEntry : prev,
        0
    );

    return (
        <div ref={dragRef} style={{ opacity }}>
            <Button
                variant="secondary"
                icon={faAnchor}
                grow
                iconProps={{ size: "lg", className: "mr-1" }}
                className="w-full"
                onClick={onClick}
            >
                <div className="flex items-center">
                    <div className="text-left">
                        <span>{t(config.label)}</span>
                    </div>
                    <div className="text-right grow">
                        {!!config.tokens > 0 && (
                            <span className="block text-tertiary text-xs">
                                {t("text.x_tokens", { x: config.tokens })}
                            </span>
                        )}
                        {!!tokensPerEntry > 0 && (
                            <span className="block text-tertiary text-xs">
                                {t("text.x_tokens_per_entry", {
                                    x: tokensPerEntry,
                                })}
                            </span>
                        )}
                    </div>
                </div>
            </Button>
        </div>
    );
};
