import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Label } from "./Label";
import { Collapsible, CollapsibleGroup } from "./Collapsible";
import { Textbox } from "./Textbox";
import { useEffect, useState } from "react";

export const CashOutOptions = ({ value, onChange, className }) => {
    const { t } = useTranslation();
    const [paypalEmail, setPaypalEmail] = useState(
        value?.type === "paypal" ? value.value : ""
    );
    const [bankAccount, setBankAccount] = useState(
        value?.type === "bank" ? value.value : ""
    );

    useEffect(() => {
        setPaypalEmail(value?.type === "paypal" ? value.value : "");
        setBankAccount(value?.type === "bank" ? value.value : "");
    }, [value]);

    useEffect(() => {
        if (!paypalEmail) {
            return;
        }

        onChange?.({ type: "paypal", value: paypalEmail });
    }, [paypalEmail, onChange]);

    useEffect(() => {
        if (!bankAccount) {
            return;
        }

        onChange?.({ type: "bank", value: bankAccount });
    }, [bankAccount, onChange]);

    return (
        <div className={classNames(className)}>
            <Label className="block mb-2">{t("label.cash_out_options")}</Label>
            <CollapsibleGroup>
                <Collapsible
                    title={t("title.paypal")}
                    checked={value?.type === "paypal"}
                >
                    <Textbox
                        label={t("label.email")}
                        placeholder={t("placeholder.paypal_email")}
                        value={paypalEmail}
                        onChange={(e) => setPaypalEmail(e.target.value)}
                        type="email"
                    />
                </Collapsible>
                <Collapsible
                    title={t("title.bank_account")}
                    checked={value?.type === "bank"}
                >
                    <Textbox
                        placeholder={t("placeholder.bank_account")}
                        value={bankAccount}
                        onChange={(e) => setBankAccount(e.target.value)}
                        charSpaceCount={4}
                        maxLength={27}
                        uppercase
                    />
                </Collapsible>
            </CollapsibleGroup>
        </div>
    );
};
