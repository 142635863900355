import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Tab } from "../Tabs";
import { LanguageSelect } from "../selects/LanguageSelect";
import { StageEditor } from "../StageEditor/StageEditor";
import { StageEditorContextProvider } from "../StageEditor/StageEditorContext";
import { evaluateTokens } from "lib/src/stageStudio/elements";
import { StageDialog } from "../dialogs/StageDialog";
import { Button } from "../Button";

export const StageStudioTab = ({ campaign: campaignProp = {}, ...props }) => {
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const [stageConfig, setStageConfig] = useState(campaignProp.stageConfig);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    useEffect(() => {
        setStageConfig(campaignProp?.stageConfig);
    }, [campaignProp]);

    const tokens = evaluateTokens(stageConfig);

    return (
        <Tab {...props}>
            <StageEditorContextProvider>
                <div className="space-y-4">
                    <div className="flex justify-end">
                        <Button
                            variant="secondary"
                            onClick={() => setIsPreviewOpen(true)}
                        >
                            {t("button.preview")}
                        </Button>
                    </div>
                    <StageEditor
                        advertiser={{
                            ...(userContext.user?.advertiser || {}),
                            stageConfig,
                        }}
                        onChange={(v) => {
                            setStageConfig(v);
                        }}
                    />
                    {tokens > 0 && (
                        <div className="text-right">
                            <span className="text-tertiary">
                                {t("text.tokens_per_view", {
                                    tokens,
                                })}
                            </span>
                        </div>
                    )}
                </div>
            </StageEditorContextProvider>
            <StageDialog
                advertiser={{
                    ...(userContext.user?.advertiser || {}),
                    stageConfig,
                }}
                open={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
            />
        </Tab>
    );
};
