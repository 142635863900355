import { cloneElement, useState } from "react";
import { Card } from "./Card";
import { Button } from "./Button";
import {
    faAngleDown,
    faCheck,
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Icon } from "./Icon";

export const Collapsible = ({ title, children, className, checked }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Card className={classNames("!p-2", className)}>
            <div
                className="p-2 cursor-pointer flex"
                onClick={() => setExpanded(!expanded)}
            >
                <span className="grow flex items-center space-x-2">
                    <span>{title}</span>
                    {checked && <Icon icon={faCheck} className="!text-cash" />}
                </span>
                <Button
                    size="extraSmall"
                    icon={faAngleDown}
                    variant="tertiary"
                    iconClassName={classNames("transition-all", {
                        "rotate-180": expanded,
                    })}
                />
            </div>
            {expanded && <div className="mt-2 px-2 pb-2">{children}</div>}
        </Card>
    );
};

export const CollapsibleGroup = ({ children }) => {
    return (
        <div className="first">
            {children.map((child) => {
                const clonedChild = cloneElement(child, {
                    className: "first:!rounded-b-none last:!rounded-t-none",
                });

                return clonedChild;
            })}
        </div>
    );
};
