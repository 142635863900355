import classNames from "classnames";
import { StageElement } from "./StageElement";
import { useDrop } from "react-dnd";
import { AdvertiserLogo } from "../AdvertiserLogo";

export const StageRenderer = ({
    advertiser,
    onElementClick,
    onElementDrop,
    onElementSort,
    className,
    isEditor,
    ...props
}) => {
    if (!advertiser?.stageConfig?.length) {
        return null;
    }

    const renderElements = advertiser.stageConfig.map((v, i) => (
        <div>
            <StageElement
                key={`${v.type}-${v.id}`}
                element={v}
                onClick={onElementClick}
                draggable={isEditor}
            />
            {!!onElementDrop && (
                <Dropzone
                    index={i + 1}
                    value={advertiser.stageConfig}
                    onDrop={onElementDrop}
                />
            )}
            {!!onElementSort && (
                <Dropzone
                    index={i + 1}
                    value={advertiser.stageConfig}
                    onDrop={(index, element) =>
                        onElementSort(index, v.sort, element)
                    }
                    accept="element"
                />
            )}
        </div>
    ));

    return (
        <div
            className={classNames(
                "grid overflow-hidden w-full",
                { "gap-4": !isEditor },
                className
            )}
            {...props}
        >
            <div className="flex">
                <AdvertiserLogo advertiser={advertiser} size="large" />
            </div>
            {!!onElementDrop && (
                <Dropzone
                    index={0}
                    value={advertiser.stageConfig}
                    onDrop={onElementDrop}
                />
            )}
            {!!onElementSort && (
                <Dropzone
                    index={0}
                    value={advertiser.stageConfig}
                    onDrop={(index, element) =>
                        onElementSort(index, 1, element)
                    }
                    accept="element"
                />
            )}
            {renderElements}
        </div>
    );
};

const Dropzone = ({ index, value, onDrop, accept = "sidebar-element" }) => {
    const [{ isOver, canDrop }, dropRef] = useDrop(
        () => ({
            accept: accept,
            drop: (config) => {
                if (onDrop) {
                    onDrop(index, config);
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [value]
    );

    return (
        <div className="relative">
            <div
                className={classNames(
                    "absolute h-12 w-full -translate-y-1/2 flex items-center px-2",
                    {
                        "block": canDrop,
                        "hidden": !canDrop,
                    }
                )}
                ref={dropRef}
            >
                <div
                    className={classNames(
                        "grow transition-all border-b-2 border-dashed border-cash",
                        {
                            "opacity-0": !isOver,
                            "opacity-100": isOver,
                        }
                    )}
                />
            </div>
        </div>
    );
};

export const sortStageValues = (a, b) => a.sort - b.sort;
