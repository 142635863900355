import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Headline } from "./Headline";
import { Button } from "../../Button";
import { Card } from "../../Card";
import { Price } from "../../Price";

export const ProductSlider = ({
    title,
    titleSize,
    description,
    products = [],
}) => {
    console.log({ products });

    const renderProducts = products.map((v, index) => (
        <ProductSliderProduct
            key={`product-slider-item${title}-${index}`}
            product={v}
        />
    ));

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            partialVisibilityGutter: 40, // this is optional if you are not using partialVisible props
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30, // this is optional if you are not using partialVisible props
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1.2,
            partialVisibilityGutter: 30, // this is optional if you are not using partialVisible props
        },
    };

    return (
        <div className="w-full space-y-4">
            <div>
                {!!title && <Headline title={title} size={titleSize} />}
                {!!description && (
                    <p className="text-secondary">{description}</p>
                )}
            </div>
            <Carousel
                showDots={true}
                draggable
                swipeable
                responsive={responsive}
                className="pb-8"
                itemClass="pr-2"
            >
                {renderProducts}
            </Carousel>
        </div>
    );
};

const ProductSliderProduct = ({ product }) => {
    if (!product) {
        return null;
    }

    return (
        <div>
            <Card className="!p-1">
                <div className="grid gap-2 p-2">
                    <img
                        className="w-full"
                        src={product.image?.blob || product.image}
                        draggable={false}
                    />
                    <div>
                        {!!product.title && (
                            <Headline title={product.title} size="small" />
                        )}
                        <p className="text-tertiary text-sm">
                            {product.description}
                        </p>
                    </div>
                    {!!product.price && (
                        <Price discount={product.discount}>
                            {product.price}
                        </Price>
                    )}
                    {!!product.buttonText && product.buttonLink && (
                        <a
                            href={product.buttonLink}
                            target={
                                product.buttonLink === "#" ? "_self" : "_blank"
                            }
                        >
                            <Button size="small" className="w-full">
                                {product.buttonText}
                            </Button>
                        </a>
                    )}
                </div>
            </Card>
        </div>
    );
};
