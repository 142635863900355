import { useContext, useState } from "react";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import classNames from "classnames";
import { Icon } from "../Icon";
import { faSpinner, faTicket } from "@fortawesome/free-solid-svg-icons";
import { AdVideo } from "../AdVideo";
import { LogoLoader } from "../LogoLoader";
import { UserContext } from "../context/UserContext";
import { raffleUtils } from "../../utils/raffleUtils";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../context/ApplicationContext";

export const WatchAdDialog = ({
    ad = {},
    onCancel,
    onClaim,
    isPreview,
    ...props
}) => {
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);
    const [applicationContext, applicationDispatch] =
        useContext(ApplicationContext);

    const [duration, setDuration] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [isEndScreenVisible, setIsEndScreenVisible] = useState(false);
    const [timestamps, setTimestamps] = useState([]);

    const campaign = ad?.campaign;

    if (!campaign) {
        return null;
    }

    const handleLoadedData = (e) => {
        setIsVideoLoaded(true);
    };

    const init = () => {
        setDuration(null);
        setCurrentTime(null);
        setIsVideoLoaded(false);
        setIsEndScreenVisible(false);
    };

    const handleTimeUpdate = (e) => {
        if (!duration) {
            setDuration(Math.ceil(e.target?.duration || 0));
        }

        setCurrentTime(Math.ceil(e.target.currentTime || 0));

        setTimestamps([...timestamps, e.target.currentTime]);
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        init();
    };

    const validate = () => {
        if (
            !isVideoLoaded ||
            duration === null ||
            currentTime === null ||
            duration - currentTime !== 0
        ) {
            return false;
        }

        return true;
    };

    const handleClaim = async () => {
        if (!ad) {
            return;
        }

        if (isPreview) {
            onClaim();
            return;
        }

        setIsLoadingClaim(true);

        try {
            const claimResponse = await raffleUtils.claimTicket(
                ad?.raffle?.uuid,
                {
                    adKey: ad.adKey,
                    timestamps,
                }
            );

            if (claimResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext.user,
                        tickets: Number(userContext.user.tickets || 0) + 1,
                    },
                });

                const updatedRaffle = claimResponse.data.data.raffle;
                const updatedRaffleUser = claimResponse.data.data;

                applicationDispatch({
                    type: "UPDATE_RAFFLE_WITH_USER",
                    payload: {
                        raffle: updatedRaffle,
                        raffleUser: updatedRaffleUser,
                    },
                });

                onClaim(claimResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingClaim(false);
            init();
        }

        setIsLoadingClaim(false);
        init();
    };

    const handleVideoError = (e) => {
        if (window.location.hostname === "localhost") {
            e.target.src = "/videos/demo_video.mp4";
        }
    };

    const handleVideoEnd = (e) => {
        if (!e.target) {
            return;
        }

        setTimestamps([...timestamps, e.target.currentTime]);

        setIsEndScreenVisible(true);
    };

    return (
        <Dialog size="big" {...props}>
            <div className="relative">
                <AdVideo
                    ad={ad}
                    onPlaying={handleLoadedData}
                    onTimeUpdate={handleTimeUpdate}
                    onError={handleVideoError}
                    onEnded={handleVideoEnd}
                />
                {!isVideoLoaded && (
                    <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
                        <div className="text-center">
                            <LogoLoader
                                className="w-8 h-8"
                                rowClassName="h-2"
                            />
                            <p className="text-secondary text-sm">
                                Loading ad...
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className={classNames(
                        "absolute left-0 top-0 w-full h-full flex items-center justify-center bg-black/80 rounded-lg transition",
                        {
                            "opacity-1 pointer-events-auto": isEndScreenVisible,
                            "opacity-0 pointer-events-none":
                                !isEndScreenVisible,
                        }
                    )}
                >
                    <div className="w-2/3">
                        <div className="flex items-center">
                            {!!campaign?.advertiser?.logoUrl && (
                                <img
                                    src={campaign?.advertiser?.logoUrl}
                                    className="h-6 inline-block mr-2 "
                                />
                            )}
                            <h4 className="font-bold">
                                {campaign?.advertiser?.name}
                            </h4>
                        </div>
                        {(campaign?.description ||
                            campaign?.advertiser?.description) && (
                            <p className="mt-1 text-primary text-sm whitespace-break-spaces max-h-20 lg:max-h-44 truncate">
                                {campaign?.description ||
                                    campaign?.advertiser?.description}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="grow">
                    <Button onClick={handleCancel} variant="tertiary">
                        {t("button.cancel")}
                    </Button>
                </div>
                <Button
                    disabled={!isEndScreenVisible}
                    onClick={handleClaim}
                    isLoading={isLoadingClaim}
                    rightIcon={faTicket}
                    progress={
                        !isEndScreenVisible
                            ? Math.ceil((currentTime / duration) * 100)
                            : undefined
                    }
                >
                    {t("button.claim_ticket")}
                </Button>
            </div>
        </Dialog>
    );
};
