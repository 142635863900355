import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Tab } from "../Tabs";
import { LanguageSelect } from "../selects/LanguageSelect";
import { StageEditor } from "../StageEditor/StageEditor";
import { StageEditorContextProvider } from "../StageEditor/StageEditorContext";
import { evaluateTokens } from "lib/src/stage/elements";
import { StageDialog } from "../dialogs/StageDialog";
import { Button } from "../Button";
import { campaignUtils } from "../../utils/campaignUtils";
import { Label } from "../Label";
import { getStageImageFiles } from "lib/src/stage/stages";
import {
    faEye,
    faPen,
    faPenAlt,
    faPencil,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";

export const StageStudioTab = ({ campaign, onUpdate, ...props }) => {
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const [stageConfig, setStageConfig] = useState(campaign?.stageJson);
    const [duration, setDuration] = useState(campaign.stageDuration);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (!campaign?.stageJson) {
            return;
        }

        setStageConfig(campaign.stageJson || []);
    }, [campaign?.stageJson]);

    const tokens = evaluateTokens(stageConfig);

    console.log(getStageImageFiles(stageConfig));

    const handleSave = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!validate()) {
            return;
        }

        setIsLoadingSave(true);

        const updatePayload = {
            stageJson: JSON.stringify(stageConfig),
        };

        try {
            const updateResponse = await campaignUtils.update(
                campaign?.uuid,
                updatePayload
            );

            if (updateResponse.status === 200) {
                onUpdate(updateResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingSave(false);
        }

        setIsLoadingSave(false);
    };

    const validate = () => {
        if (
            JSON.stringify(stageConfig) === JSON.stringify(campaign.stageJson)
        ) {
            return false;
        }

        return true;
    };

    return (
        <Tab {...props}>
            <StageEditorContextProvider>
                <div className="space-y-4">
                    <div className="flex justify-end space-x-2">
                        {isEditing ? (
                            <Button
                                variant="secondary"
                                onClick={() => setIsEditing(false)}
                                rightIcon={faTimes}
                            >
                                {t("button.cancel")}
                            </Button>
                        ) : (
                            <Button
                                variant="secondary"
                                onClick={() => setIsEditing(true)}
                                rightIcon={faPencil}
                            >
                                {t("button.edit")}
                            </Button>
                        )}
                        <Button
                            variant="secondary"
                            onClick={() => setIsPreviewOpen(true)}
                            rightIcon={faEye}
                        >
                            {t("button.preview")}
                        </Button>
                        <Button
                            isLoading={isLoadingSave}
                            onClick={handleSave}
                            disabled={!validate()}
                        >
                            {t("button.save")}
                        </Button>
                    </div>
                    <StageEditor
                        value={stageConfig}
                        campaign={campaign}
                        onChange={(v) => {
                            setStageConfig(v);
                        }}
                        isEditing={isEditing}
                        onEditExit={() => setIsEditing(false)}
                    />
                    <div>
                        <Label className="block mb-2">
                            {t("label.duration")}
                        </Label>
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                            <Button
                                subtitle={t("text.per_watch", {
                                    value: "0.20 €",
                                })}
                                variant={
                                    duration === 15
                                        ? "secondaryActive"
                                        : "secondary"
                                }
                                onClick={() => setDuration(15)}
                            >
                                {t("button.seconds", { value: 15 })}
                            </Button>
                            <Button
                                subtitle={t("text.per_watch", {
                                    value: "0.30 €",
                                })}
                                variant={
                                    duration === 30
                                        ? "secondaryActive"
                                        : "secondary"
                                }
                                onClick={() => setDuration(30)}
                                badge={t("badge.best_price")}
                            >
                                {t("button.seconds", { value: 30 })}
                            </Button>
                            <Button
                                subtitle={t("text.per_watch", {
                                    value: "0.40 €",
                                })}
                                variant={
                                    duration === 45
                                        ? "secondaryActive"
                                        : "secondary"
                                }
                                onClick={() => setDuration(45)}
                            >
                                {t("button.seconds", { value: 45 })}
                            </Button>
                            <Button
                                subtitle={t("text.per_watch", {
                                    value: "0.50 €",
                                })}
                                variant={
                                    duration === 60
                                        ? "secondaryActive"
                                        : "secondary"
                                }
                                onClick={() => setDuration(60)}
                            >
                                {t("button.one_minute")}
                            </Button>
                        </div>
                    </div>
                    <div className="text-right">
                        {tokens > 0 && (
                            <span className="text-tertiary mr-4">
                                {t("text.tokens_per_view", {
                                    tokens,
                                })}
                            </span>
                        )}
                    </div>
                </div>
            </StageEditorContextProvider>
            <StageDialog
                campaign={campaign}
                value={stageConfig}
                open={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
            />
        </Tab>
    );
};
