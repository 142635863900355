const sizeOptions = {
    "large": "label.large",
    "medium": "label.medium",
    "small": "label.small",
};

const extendedSizeOptions = {
    "extraLarge": "label.extra_large",
    "large": "label.large",
    "medium": "label.medium",
    "small": "label.small",
    "extraSmall": "label.extra_small",
};

export const stageStudioElements = {
    "hero": {
        label: "label.hero",
        tokens: 10,
        arguments: {
            title: {
                label: "label.title",
                type: "text",
                maxLength: 64,
            },
            text: {
                label: "label.text",
                type: "markdown",
                maxLength: 300,
            },
            image: {
                label: "label.image",
                type: "image",
            },
            buttonText: {
                label: "label.button_text",
                type: "text",
                group: "title.button",
                maxLength: 32,
            },
            buttonLink: {
                label: "label.button_link",
                type: "text",
                group: "title.button",
                maxLength: 300,
            },
        },
        defaultProps: {
            title: "Your Words, Our Magic",
            text: "Type something, and we’ll make it look fantastic. Ready?",
            buttonText: "Click here",
            buttonLink: "#",
            image: "/logo512.png",
        },
    },
    "paragraph": {
        label: "label.paragraph",
        tokens: 5,
        arguments: {
            text: {
                label: "label.text",
                type: "markdown",
                maxLength: 300,
            },
        },
        defaultProps: {
            text: "Write something here that makes people say, ‘Take my money!",
        },
    },
    "headline": {
        label: "label.headline",
        tokens: 5,
        arguments: {
            title: {
                label: "label.title",
                type: "text",
                maxLength: 64,
            },
            size: {
                label: "label.size",
                type: "select",
                options: extendedSizeOptions,
            },
        },
        defaultProps: {
            title: "Write a title so good, even you would click it",
            size: "large",
        },
    },
    "links": {
        label: "label.links",
        arguments: {
            links: {
                label: "label.items",
                type: "mvp",
                maxEntries: 9,
                tokensPerEntry: 2,
                arguments: {
                    text: {
                        label: "label.text",
                        type: "text",
                        maxLength: 64,
                    },
                    link: {
                        label: "label.link",
                        type: "text",
                        maxLength: 300,
                    },
                },
                defaultValue: {
                    text: "Click here",
                    link: "#",
                },
            },
        },
        defaultProps: {
            links: [
                {
                    text: "Click here",
                    link: "#",
                },
            ],
        },
    },
    "productSlider": {
        label: "label.product_slider",
        tokens: 5,
        arguments: {
            title: {
                label: "label.title",
                type: "text",
                group: "title.title",
                maxLength: 64,
            },
            titleSize: {
                label: "label.title_size",
                type: "select",
                group: "title.title",
                options: extendedSizeOptions,
            },
            description: {
                label: "label.description",
                type: "markdown",
                group: "title.title",
                maxLength: 300,
            },
            products: {
                label: "label.products",
                type: "mvp",
                maxEntries: 9,
                tokensPerEntry: 5,
                arguments: {
                    title: {
                        label: "label.title",
                        type: "text",
                        maxLength: 64,
                    },
                    description: {
                        label: "label.description",
                        type: "markdown",
                        maxLength: 300,
                    },
                    price: {
                        label: "label.price",
                        type: "text",
                        group: "label.price",
                        maxLength: 24,
                    },
                    discount: {
                        label: "label.discount",
                        type: "text",
                        group: "label.price",
                        maxLength: 24,
                    },
                    image: {
                        label: "label.image",
                        type: "image",
                    },
                    buttonText: {
                        label: "label.button_text",
                        type: "text",
                        group: "title.button",
                        maxLength: 32,
                    },
                    buttonLink: {
                        label: "label.button_link",
                        type: "text",
                        group: "title.button",
                        maxLength: 300,
                    },
                },
                defaultValue: {
                    title: "Product title",
                    description: "Product description",
                    price: "9.99 €",
                    image: "/logo512.png",
                    buttonText: "Click here",
                    buttonLink: "#",
                },
            },
        },
        defaultProps: {
            title: "This is the title",
            titleSize: "medium",
            description: "This is the description",
            products: [
                {
                    title: "Product title",
                    description: "Product description",
                    price: "9.99 €",
                    image: "/logo512.png",
                    buttonText: "Click here",
                    buttonLink: "#",
                },
            ],
        },
    },
};

export const evaluateTokens = (elements) => {
    if (!elements || !elements.length) {
        return null;
    }

    let tokens = 0;

    elements.forEach((v) => (tokens += evaluateTokensForElement(v)));

    return tokens;
};

export const evaluateTokensForElement = (element) => {
    const config = stageStudioElements[element.type];

    if (!config) {
        console.error(`Config for element type ${element.type} not found`);

        return 0;
    }

    let tokens = 0;

    tokens += config.tokens || 0;

    let tokensPerEntry = Object.entries(config.arguments || {}).reduce(
        (prev, [key, entry]) => {
            if (entry.tokensPerEntry > 0) {
                let fieldValue = element.props?.[key] || element[key];

                if (!fieldValue?.length) {
                    return prev;
                }

                return (
                    prev +
                    fieldValue.reduce(
                        (prev) => (prev += entry.tokensPerEntry),
                        0
                    )
                );
            }

            return prev;
        },
        0
    );

    tokens += tokensPerEntry;

    return tokens;
};

export const getElementConfigFromType = (type) => {
    let config = stageStudioElements[type];

    if (!config) {
        console.error(`Element config for type ${element.type} not found`);

        return undefined;
    }

    return config;
};
