import classNames from "classnames";

export const LogoLoader = ({
    duration = 5000,
    count = 30,
    rowClassName,
    variant = "white",
    className,
}) => {
    const rows = [];

    for (let i = 0; i < count; i++) {
        rows.push(
            <Row
                key={`logo-loader-row-${i}`}
                duration="100ms"
                variant={variant}
                delay={i * 100}
                className={rowClassName}
            />
        );
    }

    return (
        <div
            className={classNames(
                "w-4 h-4 overflow-hidden inline-block gradient-mask-y",
                className
            )}
        >
            <div
                style={{
                    animationName: "logo-loader-animation",
                    animationDuration: duration + "ms",
                    animationTimingFunction: "cubic-bezier(.35,-0.35,.50,1.50)",
                    animationIterationCount: "infinite",
                }}
            >
                <div className="grid grid-cols-1 gap-[4px]">{[rows, rows]}</div>
            </div>
        </div>
    );
};

const Row = ({ duration, delay, variant, className }) => (
    <div
        className={classNames(
            "h-1",
            {
                "bg-white/60": variant === "white",
                "bg-black/60": variant === "black",
            },
            className
        )}
    />
);
